import React from 'react'
import { observer } from 'mobx-react'
import { Button } from 'components'

const ProductContainer = props => {
  const { coupon = {}, hasAction = true, isProductActive = false } = props

  const getMonthlyPrice = () => {
    const standardPrice = (props.product.price.unit_amount/1200).toFixed(2)

    if (!coupon.id || coupon.isCouponTrial) {
      return `$${standardPrice}/month`
    }

    return (
      <>
        <s>${standardPrice}</s>
        ${(standardPrice - (standardPrice * coupon.percent_off / 100)).toFixed(2) }{`/month`}
      </>
    )
  }

  const getAnnualPrice = () => {
    const standardPrice = props.product.price.unit_amount / 100

    if (!coupon.id || coupon.isCouponTrial) {
      return `$${standardPrice}`
    }

    return (
      <>
        <s>${standardPrice}</s>
        <b>${(standardPrice - (standardPrice * coupon.percent_off / 100)).toFixed(2) }</b>
      </>
    )
  }


  return (
    <div className={props.className}>
      <div className="account-subscription-product-content">
        <span className="account-subscription-product-title">
          {props.product.name}
        </span>
        {coupon.trialEnd && (
          <div className="account-subscription-trial-period">
            {`Free until: ${new Date(coupon.trialEnd * 1000).toLocaleDateString()}`}
          </div>
        )}
        <div className="account-subscription-product-price-month">
          {getMonthlyPrice()}
        </div>
        <div className="account-subscription-product-price-year">
          {`billed annually at `}{getAnnualPrice()}
        </div>
        <div className="account-subscription-product-bullets">
          {props.product.metadata.bullets.split(';').map(bullet => (
            <div key={bullet}>• {bullet}</div>
          ))}
        </div>
        {hasAction && (
          <div className="account-subscription-product-action">
            <Button disabled={isProductActive} onClick={() => props.onProductSelect(props.product)}>
              {isProductActive ? 'Current' : 'Change'}
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

export default observer(ProductContainer)
