import React from 'react'
import { Radio } from 'antd'
import './styles.scss'

const PPRadioGroup = props => (
  <Radio.Group {...props} className={`custom-radio-group ${props.className || ''}`} />
)

export default PPRadioGroup

