import { types, flow, getPath, resolvePath, getType } from "mobx-state-tree"
import * as API_PATHS from 'utils/path-helpers/api'
import axios from 'axios'
import moment from 'moment'
import { PROGRESS_STATUSES } from 'constants/modules'
import { navigateToCareer } from 'utils/navigation'
import { careerStore } from './CareerStore'

const findStepById = (steps, id) => {
  for (let i = 0; i < steps.length; i++) {
    if (steps[i].id === id) return steps[i]
  }

  for (let i = 0; i < steps.length; i++) {
    if (steps[i].steps.length) {
      const step = findStepById(steps[i].steps, id)
      if (step) return step
    }
  }
}

const findModuleById = (modules, id) => {
  return modules.find(module => module.id === id)
}

const TCEShape = types.optional(types.array(types.number), [0, 0], [undefined, null])

const NA_CONST = 'N/A'

const prepareReadableMoney = val => val ? '$' + val.toLocaleString() : NA_CONST
const prepareReadableTime = val => val ? moment.duration(val, "days").humanize() : NA_CONST
const prepareReadableRange = (...arr) => arr.join(' - ')

const TCEModel = types
  .model('TCEModel', {
    time: TCEShape,
    cost: TCEShape,
    salary: types.optional(types.number, 0, [undefined, null]),
    predictedEarning: types.optional(types.number, 0, [undefined, null]),
  })
  .views(self => ({
    get averageTime() {
      return (self.time[0] + self.time[1]) / 2
    },
    get readableRangeTime() {
      const time1 = prepareReadableTime(self.time[0])
      const time2 = prepareReadableTime(self.time[1])
      return prepareReadableRange(time1, time2)
    },
    get readableAverageTime() {
      return prepareReadableTime(self.averageTime)
    },
    get averageCost() {
      return (self.cost[0] + self.cost[1]) / 2
    },
    get readableRangeCost() {
      const cost1 = prepareReadableMoney(self.cost[0])
      const cost2 = prepareReadableMoney(self.cost[1])
      return prepareReadableRange(cost1, cost2)
    },
    get readableAverageCost() {
      return prepareReadableMoney(self.averageCost)
    },
    get readableAverageSalary() {
      return prepareReadableMoney(self.salary)
    },
    get readablePredictedEarnings() {
      return prepareReadableMoney(self.predictedEarning)
    },
    get isTCEApplicable() {
      return !!(self.averageTime || self.averageCost || self.salary)
    },
  }))

const ModuleStepAction = types
  .model('ModuleStepActionModel', {
    id: types.identifier,
    label: types.optional(types.string, '', [undefined, null]),
    color: types.enumeration(['primary', 'secondary']),
    icon: types.optional(types.string, '', [undefined, null]),
    type: types.enumeration(['html', 'image', 'video', 'event']),
    content: types.optional(types.string, '', [undefined, null]),
    eventTemplateId: types.optional(types.string, '', [undefined, null]),
    subButtonName: types.optional(types.string, '', [undefined, null]),
    trackableForRequest: types.optional(types.boolean, false, [undefined, null]),
  })

const ModuleStep = types.compose('ModuleStepModel',
  types
    .model({
      id: types.identifier,
      title: '',
      description: '',
      steps: types.optional(types.array(types.late(() => ModuleStep)), []),
      actions: types.optional(types.array(ModuleStepAction), []),
      status: '',
      type: types.enumeration(['default', 'select']),
      selected: false,
      drillInButtonName: '',
    })
    .views(self => ({
      get isChildfree() {
        return !self.steps.length
      },
      get stepsUntilModule() {
        let items = []

        let stepOrModule = self
        items.unshift(stepOrModule)

        do {
          stepOrModule = resolvePath(stepOrModule, '../..')
          items.unshift(stepOrModule)
        }
        while (getType(stepOrModule).name === 'ModuleStepModel')

        return items
      },
      get idPath() {
        return self.stepsUntilModule.map(item => item.id).join('/')
      },
    })),
  TCEModel
)

const Module = types.compose('ModuleModel',
  types
    .model({
      id: types.identifier,
      title: '',
      description: '',
      shortDescription: '',
      status: '',
      icon: '',
      steps: types.optional(types.array(ModuleStep), []),
    })
    .views(self => ({
      get isChildfree() {
        return !self.steps.length
      },
      get idPath() {
        return self.id
      },
    })),
  TCEModel
)

export const Path = types.compose('PathModel',
  types
    .model({
      id: types.identifier,
      title: '',
      modules: types.optional(types.array(Module), []),
      inDevelopment: false,
      cheapest: false,
      fastest: false,
      preferred: false,
      profitable: false,
      tags: types.optional(types.array(types.string), []),
      isPathSelectionInProgress: false,
    })
    .actions(self => ({
      selectPath: flow(function*() {
        try {
          self.isPathSelectionInProgress = true
          yield axios.post(API_PATHS.careerPathPath(), { id: self.id })
          navigateToCareer()
          yield careerStore.getDataInitially()
        } catch (error) {
          console.log(error)
        }
        self.isPathSelectionInProgress = false
      }),
      addTag: function(tag) {
        self.tags.push(tag)
      },
    })),
  TCEModel
)
