import React, { useEffect } from 'react'
import { Router } from 'react-router-dom'
import { Provider } from 'mobx-react'
import Routes from 'routes'
import history from 'history/history'
import * as stores from 'stores'
import 'antd/dist/antd.less'
import 'shepherd.js/dist/css/shepherd.css'
import './colors.scss'
import './styles.scss'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

let stripePromise = null

window.env = process.env.REACT_APP_ENVIRONMENT
window.envNum = process.env.REACT_APP_ENVIRONMENT_NUM

if (window.env === 'prod') {
  stripePromise = loadStripe('pk_live_51H59C1JjY65cNy4Bs17kZXAyxs7TkWPQQygl6KGe54XGkKrstZhPp57Ls0TnBYV5Ubj9rraXioOvUhDV5t5D5ym900pBRj1FQs')
} else {
  stripePromise = loadStripe('pk_test_51H59C1JjY65cNy4BsSnmeXgyoy5yCxWZQCVX8WE3Y2LGqfrOUpvhziHJT71dR87KgGWeieiNSBi0ZSmSukgIr1xe007lIXLJ9l')
}

const App = () => {
  useEffect(() => {
    stores.masterStore.onInit()
  })

  return (
    <Provider {...stores}>
      <Router history={history}>
        <Elements stripe={stripePromise}>
          <Routes />
        </Elements>
      </Router>
    </Provider>
  )
}

export default App
