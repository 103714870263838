import { types, flow } from "mobx-state-tree"
import * as APP_PATHS from 'utils/path-helpers/app'
import { navigateToPathways } from 'utils/navigation'
import * as stores from 'stores'
import { accessToken } from 'utils/auth'

const MasterStore = types
  .model({})
  .actions(self => ({
    onInit: flow(function*() {
      if(accessToken.get()) {
        stores.userDataStore.getCurrentUser()
        stores.eventStore.getEventTemplates()

        const survey = yield stores.surveyStore.getSurveyUnit()
        if (stores.surveyStore.unit.id) navigateToPathways()
        yield stores.careerStore.getDataInitially()

        if (window.env !== 'prod') stores.careerStore.getMasterPaths()
      }
    }),
    onAfterRetake: flow(function*() {
      stores.careerStore.resetStoreData()
      stores.surveyStore.getSurveyUnit()

      navigateToPathways()
    }),
  }))

export const masterStore = MasterStore.create()
