import React from 'react'
import { contentContainerStyles } from './styles.module.scss'

const ContentContainer = props => {
  return (
    <main className={contentContainerStyles}>
      {props.children}
    </main>
  )
}

export default ContentContainer
