import React from 'react'
import { Radio } from 'antd'
import './styles.scss'

const PPRadioButton = props => (
  <Radio.Button {...props} className={`custom-radio-button ${props.className || ''}`} />
)

export default PPRadioButton

