import React from 'react'
import { Collapse } from 'antd'
import { observer, inject } from 'mobx-react'
import { DownOutlined } from '@ant-design/icons'


const PPCollapsePanel = ({ className, adaptiveStore, ...props }) => (
  <Collapse.Panel
    className={`custom-collapse-panel ${className || ''}`}
    {...props}
  />
)

export default inject('adaptiveStore')(observer (PPCollapsePanel))

