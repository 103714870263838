import React from 'react'
import { Checkbox } from 'antd'
import './styles.scss'

const PPCheckbox = ({ children, ...props }) => (
  <Checkbox {...props} className={`custom-checkbox ${props.className || ''}`}>
    <span className="custom-checkbox-children">
      {children}
    </span>
  </Checkbox>
)

export default PPCheckbox

