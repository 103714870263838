// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles-module__centeredLayoutStyles___3yvd3{display:flex;flex-direction:row;flex-wrap:wrap;justify-content:center;align-items:center;padding:20px 0;height:100%;width:100%}\n", ""]);
// Exports
exports.locals = {
	"centeredLayoutStyles": "styles-module__centeredLayoutStyles___3yvd3"
};
module.exports = exports;
