import React from 'react'
import { Button, Modal } from 'components'
import { editorContainerStyles } from './styles.module.scss'
import { Editor } from '@tinymce/tinymce-react'

const renderPreview = () => (
  <div className="content-type-html-content" dangerouslySetInnerHTML={{__html: window.tinyMCE.get('html-editor').getContent()}}/>
)

const onPreviewClick = () => {
  Modal.info({
    content: renderPreview(),
    width: '800px',
    icon: null,
    maskClosable: true,
    closable: true
  })
}

const EditorContainer = props => (
  <Editor
    initialValue=""
    id="html-editor"
    init={{
      height: '100%',
      menubar: false,
      plugins: [
        'advlist autolink lists link image hr',
        'charmap print preview anchor help',
        'searchreplace visualblocks code',
        'insertdatetime media table paste wordcount image'
      ],
      toolbar: `
        undo redo |
        formatselect |
        bold italic underline strikethrough |
        alignleft aligncenter alignright |
        link unlink image |
        hr |
        bullist numlist |
        outdent indent |
        code customPreview |
        help`,
      setup: (editor) => {
        editor.ui.registry.addButton('customPreview', {
          icon: 'preview',
          onAction: onPreviewClick,
        })
      },
    }}
  />
)

export default EditorContainer
