// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles-module__authContainerStyles___2BHLA{max-width:405px;padding:20px;width:100%;display:grid;grid-gap:20px;grid-template-columns:1fr 1fr}.styles-module__authContainerStyles___2BHLA .custom-form{width:100%;grid-column:1 / -1}.styles-module__authContainerStyles___2BHLA .auth-container-logo{grid-column:1 / -1;display:block;width:100%}.styles-module__authContainerStyles___2BHLA .flex-air-login{grid-column:1 / -1}.styles-module__authContainerStyles___2BHLA .flex-air-login+.sign-up-container-button{display:none}.styles-module__authContainerStyles___2BHLA h3{margin-bottom:0;grid-column:1 / -1;text-align:center}.styles-module__authContainerStyles___2BHLA .login-container-forgot-password{grid-column:1 / -1;border:none;background-color:transparent;color:#6D6E70;font-size:12px;padding:0;text-decoration:underline;cursor:pointer}@media (max-width: 405px){.styles-module__authContainerStyles___2BHLA .custom-button{grid-column:1 / -1}}.styles-module__confirmationContainerStyles___ebYjV{max-width:405px;padding:0 20px;width:100%;display:grid;grid-gap:15px;grid-template-columns:1fr;align-items:center;text-align:center}.styles-module__confirmationContainerStyles___ebYjV button{grid-column:1 / -1;justify-self:center}@media (max-width: 405px){.styles-module__confirmationContainerStyles___ebYjV{grid-template-columns:1fr;align-items:center;justify-items:center}}\n", ""]);
// Exports
exports.locals = {
	"authContainerStyles": "styles-module__authContainerStyles___2BHLA",
	"confirmationContainerStyles": "styles-module__confirmationContainerStyles___ebYjV"
};
module.exports = exports;
