import React from 'react'
import { Icon } from 'components'
import {
  PauseCircleOutlined,
  ClockCircleOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  PlusCircleOutlined,
  SyncOutlined,
} from '@ant-design/icons'

export const PROGRESS_STATUSES = {
  onHold: {
    label: 'Pending',
    action: 'onHold',
    actionLabel: 'Pending',
    color: '#FF9D00',
//    icon: <Icon name="contrast" />,
    icon: <PauseCircleOutlined />,
  },
  inProgress: {
    label: 'In Progress',
    action: 'inProgress',
    actionLabel: 'In Progress',
    color: '#1A59BB',
//    icon: <Icon name="arrow-circle-right" />,
    icon: <ClockCircleOutlined />,

  },
  completed: {
    label: 'Completed',
    action: 'completed',
    actionLabel: 'Complete',
    color: '#00C177',
//    icon: <Icon name="check-circle" />,
    icon: <CheckCircleOutlined />,
  },
  skipped: {
    label: 'Skipped',
    color: '#DE1C1C',
    action: 'skipped',
    actionLabel: 'Skip',
//    icon: <Icon name="x-circle" />,
    icon: <CloseCircleOutlined />,
  },
}

export const LOADING_ICON = {
  label: 'Loading',
  color: '#1A59BB',
  icon: <SyncOutlined spin />,
}

export const SELECT_STATUSES = {
  selected: {
    label: 'Selected',
    color: '#00C177',
    icon: <CheckCircleOutlined />,
  },
  unselected: {
    label: 'Unselected',
    color: '#FF9D00',
    icon: <PlusCircleOutlined />,
  },
}