import React from 'react'
import { observer, inject } from 'mobx-react'
import { PROGRESS_STATUSES } from 'constants/modules'
import { Link } from 'react-router-dom'
import { careerPathwayModulePath } from 'utils/path-helpers/app'
import { modulesListContainerStyles } from './styles.module.scss'

const returnModulesList = props => {
  if (!props.careerStore.modules) return null

  return props.careerStore.modules.map(module => {
    const statusData = module.status ? PROGRESS_STATUSES[module.status] : PROGRESS_STATUSES.onHold

    return (
      <Link
        to={careerPathwayModulePath(module.id)}
        className={(props.match.params.moduleId === module.id) ? "modules-list-item-active" : "modules-list-item"}
        key={module.id}
      >
        <button
          style={{ color: statusData.color }}
          className="module-list-item-status"
          title={statusData.label}
        >
          {statusData.icon}
        </button>
        <span title={module.title}>
          {module.title}
        </span>
      </Link>
    )
  })
}

const ModulesListContainer = props => (
  <div
    id="career-planner-module-list"
    className={modulesListContainerStyles}
  >
    {returnModulesList(props)}
  </div>
)

export default inject('careerStore')(observer (ModulesListContainer))