// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles-module__scholarshipsContainerStyles___BSWOw{width:100%;height:100%}.styles-module__scholarshipsContainerStyles___BSWOw .scholarships-tag-cell{display:grid;grid-gap:8px;justify-items:center;align-items:center}.styles-module__scholarshipsContainerStyles___BSWOw .scholarships-tag-cell .ant-tag{margin:0}.styles-module__scholarshipsContainerStyles___BSWOw .scholarships-link-cell{text-align:center;font-size:16px}.styles-module__scholarshipsContainerStyles___BSWOw .scholarships-link-cell a{width:100%;height:100%;display:block}.styles-module__scholarshipsContainerStyles___BSWOw .scholarships-expanded-row{display:flex;flex-direction:row}.styles-module__scholarshipsContainerStyles___BSWOw .scholarships-expanded-row-section{border-left:1px solid #f0f0f0;padding-left:16px;width:50%}.styles-module__scholarshipsContainerStyles___BSWOw .scholarships-expanded-row-section:first-child{border:none;padding-left:0}.styles-module__scholarshipsContainerStyles___BSWOw .scholarships-expanded-row-title{color:rgba(0,0,0,0.85);font-weight:500}.styles-module__scholarshipsContainerStyles___BSWOw .scholarships-search-input{height:32px;width:280px;margin-bottom:20px}\n", ""]);
// Exports
exports.locals = {
	"scholarshipsContainerStyles": "styles-module__scholarshipsContainerStyles___BSWOw"
};
module.exports = exports;
