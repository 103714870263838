import React, { useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import { useParams, Link } from 'react-router-dom'
import { PlaneLoader, LinearBreadcrumb, Breadcrumb } from 'components'
import { consultingPath, consultingCategoryPath } from 'utils/path-helpers/app'
import CategoryContainer from './CategoryContainer'
import ServiceContainer from './ServiceContainer'
import { consultationsContainerStyles } from './styles.module.scss'

const returnLinearBreadcrumbs = props => [
  <LinearBreadcrumb.Item key={'consulting'}>
    <Link to={consultingPath()}>
      {'All Services'}
    </Link>
  </LinearBreadcrumb.Item>,
  <LinearBreadcrumb.Item key={'services'}>
    <Link to={consultingCategoryPath(props.match.params.categoryId)}>
      {props.consultationsStore.getActiveCategoryTitleById(props.match.params.categoryId)}
    </Link>
  </LinearBreadcrumb.Item>
]

const returnBreadcrumb = props => {
  if (!props.match.params.categoryId) return null

  return (
    <>
      <Breadcrumb to={consultingPath()} />
      <LinearBreadcrumb>
        {returnLinearBreadcrumbs(props)}
      </LinearBreadcrumb>
    </>
  )
}

const returnTitle = props => {
  if (props.match.params.categoryId) return null

  return (
    <h2 className="consulting-view-title">
      {'Receive Guidance from Aviation Experts'}
    </h2>
  )
}

const ConsultationsContainer = props => {
  const { categoryId } = useParams()

  useEffect(() => {
    props.consultationsStore.getCategories()
    props.consultationsStore.getServices()
  }, [])

  const renderCategories = (categories) => (
    categories.map(category => <CategoryContainer key={category.id} category={category}/>)
  )

  const renderServices = (services) => (
    services.map(service => <ServiceContainer key={service.id} service={service} />)
  )

  return (
    <div className={consultationsContainerStyles}>
      <PlaneLoader
        loading={
          props.consultationsStore.areCategoriesLoading ||
          props.consultationsStore.areServicesLoading
        }
      >
        {categoryId && (
          <div className="consultations-breadcrumbs-wrapper">
          {returnBreadcrumb(props)}
          </div>
        )}
        {returnTitle(props)}
        <div className="consultations-wrapper">
          {categoryId
            ? renderServices([...props.consultationsStore.getServicesByCategoryId(categoryId)])
            : (
              <>
              {renderCategories(props.consultationsStore.categories)}
              {renderServices(props.consultationsStore.services)}
              </>
            )
          }
        </div>
      </PlaneLoader>
    </div>
  )
}

export default inject('consultationsStore')(observer (ConsultationsContainer));
