import React from 'react'
import { Button } from 'components'
import { navigateTo } from 'utils/navigation'
import { ArrowLeftOutlined } from '@ant-design/icons';

const Breadcrumb = ({ to, ...props }) => (
  <Button
    {...props}
    type="primary"
    onClick={() => navigateTo(to)}
    icon={<ArrowLeftOutlined/>}
  >
    {props.label}
  </Button>
)

export default Breadcrumb
