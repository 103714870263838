import React from 'react'
import { notAllowedStyles } from './styles.module.scss'
import { Button, CenteredLayout } from 'components'
import { observer, inject } from 'mobx-react'
import { navigateToDiscountSubscriptions, navigateToUpgradeSubscriptions } from 'utils/navigation'

const accountTypes = {
  free: {
    title: 'Standard',
    value: 100,
  },
  premium: {
    title: 'Premium',
    value: 200,
  },
  premiumPlus: {
    title: 'Premium Plus',
    value: 300,
  }
}

const NotAllowed = props => {
  if (accountTypes[props.userDataStore.user.subscription]) {
    if (accountTypes[props.subscriptionType].value <= accountTypes[props.userDataStore.user.subscription].value) {
      return props.children
    }
  }

  return (
    <CenteredLayout>
      <div className={notAllowedStyles}>
        <h3> {accountTypes[props.subscriptionType].title} Account Required</h3>
        <div>
          In order to access <bold>{props.featureName}</bold>, you need a <b>{accountTypes[props.subscriptionType].title}</b> account.
          <br />U.S. Military Veterans receive free access to premium forever. Click below to get started.
        </div>
        <div>
          <Button onClick={navigateToUpgradeSubscriptions}>
            Upgrade
          </Button>
          <Button onClick={navigateToDiscountSubscriptions}>
            Veteran Discount
          </Button>
        </div>
      </div>
    </CenteredLayout>
  )
}

export default inject('userDataStore')(observer (NotAllowed))

