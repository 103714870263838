import React from 'react'
import { modulesPathHeaderContainerStyles } from './styles.module.scss'
import { observer, inject } from 'mobx-react'
import {
  ClockCircleOutlined,
  DollarOutlined,
  AreaChartOutlined,
} from '@ant-design/icons'

const TCE_LABELS = {
  est: {
    norm: 'Estimated',
    min: 'EST',
  },
  avr: {
    norm: 'Average',
    min: 'AVG',
  }
}

const ModulesPathHeaderContainer = props => {
  const activePath = props.careerStore.activePath || {}

  const returnTCEValue = (label, value) => {
    const displayLabel = TCE_LABELS[label][props.adaptiveStore.isTabletWidth ? 'min' : 'norm']

    return (
      <div>
        {`${displayLabel}: `}
        {props.adaptiveStore.isSmallLaptopWidth ? <br /> : null }
        <strong>{value}</strong>
      </div>
    )
  }

  return (
    <div
      id="path-header-container"
      className={modulesPathHeaderContainerStyles}
    >
      <h3 className="module-path-title">
        {activePath.title}
      </h3>
      <div className="module-path-tce-wrapper">
        <div className="module-path-tce-item">
          <div className="module-path-tce-item-prefix">
            <ClockCircleOutlined />
            <br />
            To Dream Job:
          </div>

          <div className="module-path-tce-item-separator" />

          <div className="module-path-tce-item-values">
            {returnTCEValue('est', activePath.readableRangeTime)}
            {returnTCEValue('avr', activePath.readableAverageTime)}
          </div>
        </div>

        <div className="module-path-tce-item">
          <div className="module-path-tce-item-prefix">
            <DollarOutlined />
            <br />
            Cost:
          </div>

          <div className="module-path-tce-item-separator" />

          <div className="module-path-tce-item-values">
            {returnTCEValue('est', activePath.readableRangeCost)}
            {returnTCEValue('avr', activePath.readableAverageCost)}
          </div>
        </div>

        <div className="module-path-tce-item">
          <div className="module-path-tce-item-prefix">
            <AreaChartOutlined />
            <br />
            Earnings:
          </div>

          <div className="module-path-tce-item-separator" />

          <div className="module-path-tce-item-values">
            {returnTCEValue('est', activePath.readablePredictedEarnings)}
          </div>
        </div>
      </div>
    </div>
  )
}

export default inject('adaptiveStore')(inject('careerStore')(observer (ModulesPathHeaderContainer)))
