import React from 'react'
import { Collapse } from 'antd'
import './styles.scss'
import { observer, inject } from 'mobx-react'

const PPCollapse = ({ adaptiveStore, ...props }) => (
  <Collapse
    expandIconPosition={adaptiveStore.isMobileWidth ? 'right' : 'left'}
    {...props}
    className={`custom-collapse ${props.className || ''}`}
  />
)

export default inject('adaptiveStore')(observer (PPCollapse))