import React from 'react'
import ReactDOM from 'react-dom'
import { App } from 'containers'
import axios from 'axios'
import { refreshToken, accessToken } from 'utils/auth'
import { loginPath, refreshTokenPath } from 'utils/path-helpers/api'
import { authPath } from 'utils/path-helpers/app'
import history from "history/history"
import { message } from "utils/notifications"
import LogRocket from 'logrocket'

window.env = process.env.REACT_APP_ENVIRONMENT
window.envNum = process.env.REACT_APP_ENVIRONMENT_NUM

if (window.env === 'prod') {
  if (window.heap) window.heap.load("2871867649")
  LogRocket.init('ppvls9/pilotpipeline-web-app')
}

message.config({
  duration: 7,
})

axios.interceptors.request.use(
  config => {
    if (!config) return config
    if (accessToken.get()) {
      if (config.url[0] === '/') {
        config.headers['Authorization'] = `Bearer ${accessToken.get()}`
      }
    }
    return config
  },
  error => Promise.reject(error)
)

let getFreshTokenRequest = null

axios.interceptors.response.use(
  response => response,
  error => {
    if (!(error.response && error.response.status === 401)) {
      return Promise.reject(error)
    }

    if (error.config.url === loginPath()) {
      return Promise.reject(error)
    }

    if (error.config.url === refreshTokenPath()) {
      return Promise.reject(error)
    }

    if (refreshToken.get()) {
      if (!getFreshTokenRequest) {
        return (getFreshTokenRequest = axios
          .post(refreshTokenPath(), { refreshToken: refreshToken.get() })
          .then(response => {
            accessToken.set(response.data.accessToken)
            return axios(error.config)
          })
          .catch(error => {
            if (error.response && error.response.status >= 400) {
              history.push(authPath())
              refreshToken.remove()
              accessToken.remove()
              return Promise.reject(error)
            }
          })
          .finally(() => {
            getFreshTokenRequest = null
          }))
      } else {
        return getFreshTokenRequest.then(() => {
          return axios(error.config)
        })
      }
    } else {
      history.push(authPath())
      refreshToken.remove()
      accessToken.remove()
      return Promise.reject(error)
    }
  }
)

ReactDOM.render(<App />, document.getElementById('root'))
