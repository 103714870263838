import React from 'react'
import { applicationContainerStyles } from './styles.module.scss'
import * as paths from 'utils/path-helpers/app'
import { inject, observer } from 'mobx-react'

const ApplicationContainer = props => {
  return (
    <div className={applicationContainerStyles}>
      {props.children}
    </div>
  )
}

export default inject('adaptiveStore')(observer (ApplicationContainer))
