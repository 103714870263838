// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles-module__notAllowedStyles___tx6MQ{display:flex;flex-direction:column;text-align:center;font-size:16px;align-items:center}.styles-module__notAllowedStyles___tx6MQ button{margin:16px 0 0 8px}\n", ""]);
// Exports
exports.locals = {
	"notAllowedStyles": "styles-module__notAllowedStyles___tx6MQ"
};
module.exports = exports;
