import React from 'react'
import { modulesContainerStyles } from './styles.module.scss'
import { ModuleStep, Breadcrumb, LinearBreadcrumb, Link } from 'components'
import { observer, inject } from 'mobx-react'
import ModulesListContainer from './ModulesListContainer'
import { getHigherLevelPath } from 'utils/path-helpers'
import { careerPathwayModulePath } from 'utils/path-helpers/app'

const returnLinearBreadcrumbs = props => {
  let viewStep = {}

  if (props.match.params.viewDataId) {
    if (props.activeModuleId !== props.match.params.viewDataId) {
      viewStep = props.careerStore.stepInModuleById(props.activeModuleId, props.match.params.viewDataId) || {}
    } else {
      return null
    }
  }

  viewStep.stepsUntilModule.forEach(console.log)

  return viewStep.stepsUntilModule.map(stepOrModule => (
    <LinearBreadcrumb.Item>
      <Link to={careerPathwayModulePath(stepOrModule.idPath)}>
        {stepOrModule.title}
      </Link>
    </LinearBreadcrumb.Item>
  ))
}

const returnBreadcrumb = props => {
  if (!props.activeModuleId) return null
  if (props.activeModuleId === props.match.params.viewDataId) return null

  return (
    <>
      <Breadcrumb
        to={getHigherLevelPath(props.match.url)}
      />
      <LinearBreadcrumb>
        {returnLinearBreadcrumbs(props)}
      </LinearBreadcrumb>
    </>
  )
}

const returnModuleShortDescription = props => {
  if (!props.activeModuleId) return null
  if (props.activeModuleId !== props.match.params.viewDataId) return null

  const viewModule = props.careerStore.moduleById(props.activeModuleId) || {}

  return (
    <h2 className="modules-module-view-title">
      {viewModule.shortDescription}
    </h2>
  )
}

const returnViewSteps = props => {
  let viewModuleOrStep = {}

  if (props.match.params.viewDataId) {
    if (props.activeModuleId === props.match.params.viewDataId) {
      viewModuleOrStep = props.careerStore.moduleById(props.match.params.viewDataId) || {}
    } else {
      viewModuleOrStep = props.careerStore.stepInModuleById(props.activeModuleId, props.match.params.viewDataId) || {}
    }
  }

  if (!viewModuleOrStep.steps) return null

  return viewModuleOrStep.steps.map((viewData, index) => (
    <ModuleStep
      {...viewData}
      index={index+1}
      key={index}
      stepReference={viewData}
      viewModuleOrStep={viewModuleOrStep}
      match={props.match}
      activeModuleId={props.activeModuleId}
    />
  ))
}

const ModulesContainer = props => {
  const activePath = props.careerStore.activePath || {}

  return (
    <div className={modulesContainerStyles}>
      <div>
        <div className="modules-module-title-wrapper">
          {returnBreadcrumb(props)}
          {returnModuleShortDescription(props)}
        </div>
        <div className="modules-module-view-steps">
          {returnViewSteps(props)}
        </div>
      </div>
    </div>
  )
}

export default inject('careerStore')(observer (ModulesContainer))
