// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles-module__tileNavigatorStyles___1CdvJ{width:100%;display:grid;grid-template-columns:repeat(auto-fill, minmax(340px, 1fr));grid-auto-rows:minmax(180px, max-content);grid-gap:20px}.styles-module__tileNavigatorStyles___1CdvJ .tile-navigator-tile{background-color:#F3F3F4;border:none;color:#6D6E70;font-size:36px;border-radius:15px;cursor:pointer;padding:20px}.styles-module__tileNavigatorStyles___1CdvJ .tile-navigator-tile img{width:100%;max-width:310px}@media (max-width: 600px){.styles-module__tileNavigatorStyles___1CdvJ{grid-gap:10px}.styles-module__tileNavigatorStyles___1CdvJ .tile-navigator-tile{padding:10px}}\n", ""]);
// Exports
exports.locals = {
	"tileNavigatorStyles": "styles-module__tileNavigatorStyles___1CdvJ"
};
module.exports = exports;
