// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles-module__avatarPickerComponentStyles___1PApI .avatar-picker-input-wrapper{text-align:center;margin-top:20px}.styles-module__avatarPickerComponentStyles___1PApI #avatar-picker-file-input{display:none}\n", ""]);
// Exports
exports.locals = {
	"avatarPickerComponentStyles": "styles-module__avatarPickerComponentStyles___1PApI"
};
module.exports = exports;
