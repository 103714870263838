import { types, flow } from "mobx-state-tree"
import * as API_PATHS from 'utils/path-helpers/api'
import axios from 'axios'
import { PROGRESS_STATUSES } from 'constants/modules'
import { navigateToCareer } from 'utils/navigation'
import { Path } from './Path'

//const findMinTCE = (paths, param) => paths.reduce((next, current) => (next[param] < current[param]) ? next : current)
//const findMaxTCE = (paths, param) => paths.reduce((next, current) => (next[param] > current[param]) ? next : current)
const findPathByBestTCEParam = (paths, param) => paths.find(path => path[param])

const PathwaysSelectionStore = types
  .model('PathwaysSelectionStore', {
    paths: types.optional(types.array(Path), []),
    arePathsLoading: true,
  })
  .actions(self => ({
    getPaths: flow(function*() {
      try {
        self.arePathsLoading = true
        const response = yield axios.get(API_PATHS.careerPathsPath())
        self.paths = response.data
        self.setPathsTags()
      } catch (error) {
        console.log(error)
      }
      self.arePathsLoading = false
    }),
    setPathsTags: function() {
      let path = null
      path = findPathByBestTCEParam(self.paths, 'fastest')
      if (path) path.addTag('Fastest')

      path = findPathByBestTCEParam(self.paths, 'cheapest')
      if (path) path.addTag('Least Expensive')

      path = findPathByBestTCEParam(self.paths, 'profitable')
      if (path) path.addTag('Most Profitable')
    },
  }))

export const pathwaysSelectionStore = PathwaysSelectionStore.create()
