import React, { Suspense, lazy } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import * as APP_PATHS from 'utils/path-helpers/app'

const Application = lazy(() => import('./Application'))
const Admin = lazy(() => import('./Admin'))
const Registration = lazy(() => import('./Registration'))
const Technical = lazy(() => import('./Technical'))

const Routes = () => (
  <Suspense fallback={<div/>}>
    <Switch>
      <Route path={APP_PATHS.authPath()} component={Registration} />
      <Route path={APP_PATHS.appPath()} component={Application} />
      <Route path={APP_PATHS.adminPath()} component={Admin} />
      <Route path={APP_PATHS.technicalPath()} component={Technical} />
      <Redirect to={APP_PATHS.authPath()} />
    </Switch>
  </Suspense>
)

export default Routes