import React from 'react'
import { confirmationContainerStyles } from './styles.module.scss'
import { Button } from 'components'
import { Link } from 'react-router-dom'
import { authPath } from 'utils/path-helpers/app'
import { CenteredLayout } from 'components'

const ConfirmationContainer = props => (
  <CenteredLayout>
    <div className={confirmationContainerStyles}>
      <h2>
        Please check your email, we just sent you a confirmation!
      </h2>
      <Button type="primary">
        <Link to={authPath()}>
          Back to login
        </Link>
      </Button>
    </div>
  </CenteredLayout>
)

export default ConfirmationContainer
