import React from 'react'
import { Menu } from 'antd'
import './styles.scss'

const PPMenu = props => (
  <Menu {...props} className={`custom-menu ${props.className || ''}`} />
)

export default PPMenu

