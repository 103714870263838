import React from 'react'
import { Input } from 'antd'
import './styles.scss'

const PPInputSearch = props => (
  <Input.Search {...props} className={`custom-input ${props.className || ''}`} />
)

export default PPInputSearch

