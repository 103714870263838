import { types } from "mobx-state-tree"

const AdaptiveStore = types
  .model("AdaptiveStore", {
    isAsideMenuVisible: false,
    windowWidth: 0
  })
  .views(self => ({
    get isSmallLaptopWidth() {
      return self.windowWidth <= 1590
    },
    get isTabletWidth() {
      return self.windowWidth <= 1250
    },
    get isMobileWidth() {
      return self.windowWidth <= 600
    },
  }))
  .actions(self => ({
    afterCreate: () => {
      window.addEventListener('resize', self.onWindowResize)
      self.windowWidth = window.innerWidth
    },
    onWindowResize: event => {
      self.windowWidth = event.target.innerWidth
    },
    onSwipe: event => {
      if (event.dir === 'Right') {
        self.showAsideMenu()
      }
      if (event.dir === 'Left') {
        self.hideAsideMenu()
      }
    },
    hideAsideMenu: () => {
      if(self.isAsideMenuVisible) {
        document.body.style.overflowY = ''
        self.isAsideMenuVisible = false
      }
    },
    showAsideMenu: () => {
      if(!self.isAsideMenuVisible) {
        document.body.style.overflowY = 'hidden'
        self.isAsideMenuVisible = true
      }
    }
  }))

export const adaptiveStore = AdaptiveStore.create()
