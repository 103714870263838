//api
export const apiPath = () => '/api'

//auth
export const authPath = () => `${apiPath()}/auth`
export const loginPath = () => `${authPath()}/login`
export const logoutPath = () => `${authPath()}/logout`
export const refreshTokenPath = () => `${authPath()}/refresh`

//user
export const usersPath = () => `${apiPath()}/users`
export const currentUserPath = () => `${usersPath()}/current`
export const restorePasswordPath = () => `${usersPath()}/password`
export const avatarUploadPath = () => `${currentUserPath()}/avatar`

//profiles
export const profilesPath = () => `${apiPath()}/profiles`
export const currentProfilePath = () => `${profilesPath()}/current`
export const currentProfileResetPath = () => `${currentProfilePath()}/reset`
export const currentProfileResetProgressPath = () => `${currentProfilePath()}/resetProgress`
export const currentProfileAnswersPath = () => `${currentProfilePath()}/answers`
export const currentProfileModulesProgressPath = () => `${currentProfilePath()}/progress/modules`
export const currentProfileStepsProgressPath = () => `${currentProfilePath()}/progress/steps`
export const currentProfileStepsSelectionPath = () => `${currentProfilePath()}/selection/steps`
export const currentProfileEventsPath = () => `${currentProfilePath()}/events`
export const currentProfileTasksPath = () => `${currentProfilePath()}/tasks`
export const modulesPath = () => `${profilesPath()}/modules`
export const profilesProgressPath = () => `${profilesPath()}/progress`

//survey
export const surveyPath = () => `${apiPath()}/survey`
export const surveyUnitPath = () => `${surveyPath()}/units`

//career
export const careerPath = () => `${apiPath()}/career`
export const careerAllPathsPath = () => `${careerPath()}/allPaths`
export const careerPathPath = () => `${careerPath()}/path`
export const careerPathsPath = () => `${careerPath()}/paths`
export const careerProgressPath = () => `${careerPath()}/progress`
export const careerSelectionPath = () => `${careerPath()}/selection`
export const careerContactMePath = (id) => `${careerPath()}/${id}/contact-me`

//timeline
export const timelinePath = () => `${apiPath()}/timeline`
export const timelineEventPath = () => `${timelinePath()}/event`
export const timelineTaskStatusPath = () => `${timelinePath()}/task/status`
export const timelineTemplatesPath = () => `${timelinePath()}/templates`

//subscriptions
export const subscriptionsPath = () => `${apiPath()}/subscriptions`
export const subscriptionsCouponsPath = () => `${subscriptionsPath()}/coupons`
export const subscriptionsTrialEndPath = () => `${subscriptionsPath()}/trial-end`
export const subscriptionByIdPath = id => `${subscriptionsPath()}/${id}`
export const updateSubscriptionsPath = () => `${subscriptionsPath()}/update`
export const productsSubscriptionsPath = () => `${subscriptionsPath()}/products`
export const pricesSubscriptionsPath = () => `${subscriptionsPath()}/prices`
export const retryInvoicePath = () => `${subscriptionsPath()}/retryInvoice`
export const cancelSubscriptionsPath = () => `${subscriptionsPath()}/cancel`
export const customerSubscriptionsPath = () => `${subscriptionsPath()}/customer`
export const govxVerificationPath = () => `${subscriptionsPath()}/govxVerification`

//scholarship
export const scholarshipPath = () => `${apiPath()}/scholarship`

//resources
export const resourcesPath = () => `${apiPath()}/resource`

//consultations
export const categoriesPath = () => `${apiPath()}/categories`
export const servicesPath = () => `${apiPath()}/consultation`
