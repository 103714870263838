import React, { useState, useCallback, useEffect } from 'react'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { Button } from 'components'
import { avatarPickerComponentStyles } from './styles.module.scss'

const AvatarPicker = props => {
  const [upImg, setUpImg] = useState()
  const [imgRef, setImgRef] = useState(null)
  const [crop, setCrop] = useState({ unit: '%', width: 75, aspect: 1 / 1 })

  useEffect(() => {
    return () => {
      document.getElementById('avatar-picker-file-input').value = null
      setImgRef(null);
    }
  }, [])

  const onSelectFileClick = () => {
    document.getElementById('avatar-picker-file-input').click()
  }

  const onSelectFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => setUpImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  const onLoad = useCallback(img => {
    setImgRef(img);
  }, [])

  const makeClientCrop = async crop => {
    if (imgRef && crop.width && crop.height) {
      createCropPreview(imgRef, crop, 'newFile.jpeg');
    }
  }

  const createCropPreview = async (image, crop, fileName) => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    )

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          reject(new Error('Canvas is empty'))
          return;
        }
        blob.name = fileName
        props.onCropEnd && props.onCropEnd(blob)
      }, 'image/jpeg')
    })
  }

  return (
    <div className={avatarPickerComponentStyles}>
      <ReactCrop
        src={upImg}
        onImageLoaded={onLoad}
        crop={crop}
        onChange={setCrop}
        onComplete={makeClientCrop}
        circularCrop
        minWidth={50}
        minHeight={50}
      />
      <div className="avatar-picker-input-wrapper">
        <input
          id="avatar-picker-file-input"
          type="file"
          accept="image/*"
          onChange={onSelectFile}
        />
        <Button
          onClick={onSelectFileClick}
          type="primary"
        >
          {imgRef ? 'Select another' : 'Select an image'}
        </Button>
      </div>
    </div>
  )
}

export default AvatarPicker