import React from 'react'
import { observer, inject } from 'mobx-react'
import { SurveyContainer, PathwaySelectionContainer } from 'containers'

const PreferredPathwaysContainer = props => {
  if (props.surveyStore.unit.id) {
    return <SurveyContainer />
  } else {
    return <PathwaySelectionContainer preferred />
  }
}

export default inject('surveyStore')(observer (PreferredPathwaysContainer))
