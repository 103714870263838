import React from 'react'
import { DatePicker } from 'antd'
import './styles.scss'

const PPDatePicker = ({ format = ['M/D/YYYY', 'MM/DD/YYYY'], placeholder = 'MM/DD/YYYY', ...props }) => (
  <DatePicker
    {...props}
    format={format}
    placeholder={placeholder}
    className={`custom-date-picker ${props.className || ''}`}
  />
)

export default PPDatePicker
