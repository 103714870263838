import React from 'react'
import { Button, Input, Form, Link } from 'components'
import { authContainerStyles } from './styles.module.scss'
import { observer, inject } from 'mobx-react'
import { CenteredLayout } from 'components'
import { getLogoPath } from 'utils/instances'
import { authPath } from 'utils/path-helpers/app'

const passwordRules = [
  {
    min: 8,
    message: 'Min pass length == 8',
  },
  {
    pattern: new RegExp('[0-9]'),
    message: 'No digits',
  },
  {
    pattern: new RegExp('[A-Z]'),
    message: 'No uppercase',
  },
  {
    pattern: new RegExp('[a-z]'),
    message: 'No lowercase',
  },
  {
    required: true,
    message: 'Please provide your password',
  },
]

const confirmPasswordRules = [
  {
    required: true,
    message: 'Passwords must match',
  },
  ({ getFieldValue }) => ({
    validator(rule, value) {
      if (!value || getFieldValue('password') === value) {
        return Promise.resolve()
      }
      return Promise.reject('Passwords do not match')
    },
  }),
]

const initialValues = {
  password: '',
  confirmPassword: '',
}

const ResetPasswordContainer = props => {
  const onFormSubmit = values => {
    const payload = {
      password: values.password,
      code: new URLSearchParams(window.location.search).get('code'),
    }

    props.userDataStore.resetPassword(payload)
  }

  return (
    <CenteredLayout>
      <div className={authContainerStyles}>
        <img
          className='auth-container-logo'
          src={getLogoPath()}
          alt="logo"
        />
        <Form id="reset-password-form" onFinish={onFormSubmit} initialValues={initialValues}>
          <Form.Item label="Password" name="password" rules={passwordRules}>
            <Input.Password placeholder="Enter" maxLength={50} />
          </Form.Item>
          <Form.Item label="Confirm password" name="confirmPassword" rules={confirmPasswordRules}>
            <Input.Password placeholder="Enter" maxLength={50} />
          </Form.Item>
        </Form>
        <Button type="primary" color="secondary" className="register-container-button" >
          <Link to={authPath()}>
            Back to login
          </Link>
        </Button>
        <Button htmlType="submit" form="reset-password-form" type="primary" color="secondary">
          Reset password
        </Button>
      </div>
    </CenteredLayout>
  )
}

export default inject('userDataStore')(observer (ResetPasswordContainer))
