import React from 'react'
import { tileNavigatorStyles } from './styles.module.scss'
import { navigateTo } from 'utils/navigation'

const TileNavigator = props => (
  <div className={tileNavigatorStyles}>
    {props.tiles.map((tile, index) => (
      <button
        onClick={() => {
          if (tile.onClick) tile.onClick()
          if (tile.to) navigateTo(tile.to)
        }}
        className="tile-navigator-tile"
        key={index}
      >
        {tile.label}
      </button>
    ))}
  </div>
)

export default TileNavigator

