import React from 'react'
import { comingSoonContainerStyles } from './styles.module.scss'

const ConfirmationContainer = props => (
  <div className={comingSoonContainerStyles}>
    <h2>
      Coming soon!
    </h2>
  </div>
)

export default ConfirmationContainer
