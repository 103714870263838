import React, { useEffect } from 'react'
import { modulesViewContainerStyles, modulesNoContentStyles } from './styles.module.scss'
import { observer, inject } from 'mobx-react'
import { navigateTo } from 'utils/navigation'
import { careerPathwayModulePath, pathwaysPath } from 'utils/path-helpers/app'
import { PlaneLoader, Select, Link, Modal } from 'components'

import Shepherd from 'shepherd.js'

const introSelectors = [
  '#career-planner-module-list',
  '#career-planner-module-list > a',
  '#path-header-container',
  '.module-path-tce-item:nth-child(1)',
  '.module-path-tce-item:nth-child(2)',
  '.module-path-tce-item:nth-child(3)',
  '.modules-module-view-steps > *:first-child',
  '.modules-module-view-steps > *:first-child .module-step-status',
]

  const tour = new Shepherd.Tour({
    tourName: 'career-planner-tour',
    defaultStepOptions: {
      canClickTarget: false,
      scrollTo: true,
      exitOnEsc: true,
      includeStyles: false,
      modalOverlayOpeningPadding: 5,
      modalOverlayOpeningRadius: 5,
      cancelIcon: {
        enabled: true,
      },
      popperOptions: {
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 15],
            },
          },
        ],
      },
      buttons: [
        {
          classes: 'ant-btn custom-button ant-btn-primary ant-btn-round override-shepherd-button',
          text: 'Back',
          action: function() {
            this.back()
          },
        },
        {
          classes: 'ant-btn custom-button ant-btn-primary ant-btn-round override-shepherd-button',
          text: 'Next',
          action: function() {
            this.next()
          },
        },
      ],
    },
    useModalOverlay: true,
    steps: [
      {
        attachTo: { element: introSelectors[0], on: 'auto' },
        text: 'This menu includes all the modules that makeup your pathway.',
      },
      {
        attachTo: { element: introSelectors[1], on: 'auto' },
        text: 'Module’s will reflect a pending, in progress, or complete status. Completing steps within the module will automatically update the module’s status.',
      },
      {
        attachTo: { element: introSelectors[2], on: 'auto' },
        text: 'This is the financial planner header. It provides real estimates for your career time, cost, and earnings based on industry data.',
      },
      {
        attachTo: { element: introSelectors[3], on: 'auto' },
        text: 'This section estimates the time that it takes to reach your Retirement Job. Making different selections within the pathway (i.e. flight school type or pre-retirement job) will change this estimate.'
      },
      {
        attachTo: { element: introSelectors[4], on: 'auto' },
        text: 'This section estimates the total cost of your required flight training. Choosing a different flight school type will change this estimate.'
      },
      {
        attachTo: { element: introSelectors[5], on: 'auto' },
        text: 'This section estimates your cumulative career earnings from now until your retirement age. Choosing different jobs (pre-retirement or retirement) will change this estimate.'
      },
      {
        attachTo: { element: introSelectors[6], on: 'auto' },
        text: 'This is a step within a module. Each step contains information, videos, or action items. Steps can also contain nested children steps.'
      },
      {
        attachTo: { element: introSelectors[7], on: 'auto' },
        text: 'Similar to a module in your pathway, each step also has a status. The status of steps with nested children steps can only be changed by completing the children steps.'
      },
      {
        text: 'Congratulations! You are now ready to take Pilot Pipeline out flying!',
        buttons: [
          {
            classes: 'ant-btn custom-button ant-btn-secondary ant-btn-round override-shepherd-button',
            text: 'Back',
            action: function() {
              this.back()
            },
          },
          {
            classes: 'ant-btn custom-button ant-btn-primary ant-btn-round override-shepherd-button',
            text: 'Finish',
            action: function() {
              this.next()
            },
          },
        ],
      },
    ]
  })

const ModulesViewContainer = props => {
  useEffect(() => {
    if (!props.match.params.moduleId) {
      if (props.careerStore.firstUncompletedModule) {
        navigateTo(careerPathwayModulePath(props.careerStore.firstUncompletedModule.id))
      }
    }
  }, [props.match.params.moduleId, props.careerStore.firstUncompletedModule])

  useEffect(() => {
    const onInactive = inactiveTour => {
      if (tour.tourName === inactiveTour.tourName) {
        props.userDataStore.setShowPlannerDemo(false)
        props.userDataStore.completePlannerDemo()
      }
    }

    Shepherd.on('inactive', onInactive)

    return () => {
      Shepherd.off('inactive', onInactive)
    }
  }, [])

  useEffect(() => {
    if (props.userDataStore.isUserDataLoading) return
    if (!props.careerStore.isActivePathPresented) return

    if (!props.userDataStore.isShowPlannerDemo) {
      if (props.userDataStore.user.jsonStorage.isPlannerDemoSkipped) return
      if (props.userDataStore.user.jsonStorage.isPlannerDemoFinished) return
    }

    Modal.confirm({
      title: 'Let’s Preflight!',
      content: 'Take 60 Seconds to Preflight the Career Planner. Feel free to use your arrow keys to navigate.',
      okText: 'Show demo',
      cancelText: 'Skip demo',
      onOk: () => {
        tour.start()
      },
      onCancel: () => {
        props.userDataStore.setSkipPlannerDemo(true)
        props.userDataStore.setShowPlannerDemo(false)
      },
      icon: null,
      maskClosable: true,
    })
  }, [
    props.userDataStore.isUserDataLoading,
    props.careerStore.isActivePathPresented,
    props.userDataStore.user.jsonStorage.isPlannerDemoSkipped,
    props.userDataStore.user.jsonStorage.isPlannerDemoFinished,
  ])

  const returnAllPathSelect = () => {
    if (window.env === 'prod') return null

    return (
      <Select
        style={{ width: 200, marginBottom: 20 }}
        onChange={props.careerStore.setActivePath}
      >
        {returnPathSelectOptions()}
      </Select>
    )
  }

  const returnPathSelectOptions = () => props.careerStore.masterPaths.map(path => (
    <Select.Option key={path.id} value={path.id}>
      {path.title}
    </Select.Option>
  ))

  const renderContent = () => props.careerStore.isActivePathPresented ? (
    <div className={modulesViewContainerStyles}>
      {props.children}
    </div>
  ) : (
    <div className={modulesNoContentStyles}>
      <h2>
        In order to proceed please
        <br />
        <Link to={pathwaysPath()}>
          finish your pathway selection
        </Link>
      </h2>
    </div>
  )

  return (
    <PlaneLoader
      transparent={!props.careerStore.isInitialLoading}
      loading={props.careerStore.isInitialLoading || props.careerStore.isActivePathDataReloading}
    >
      {returnAllPathSelect()}
      {renderContent()}
    </PlaneLoader>
  )
}

export default inject('userDataStore')(inject('masterStore')(inject('careerStore')(observer (ModulesViewContainer))))
