import { types, flow } from 'mobx-state-tree'
import * as paths from 'utils/path-helpers/api'
import axios from 'axios'
import { baseApplicableTrainingList, baseEligibilityCriteriaList } from 'constants/scholarship'

const filterUnique = (arr, field) => {
  const unique = new Set()
  arr.forEach(e => {
    const value = e[field]
    if (Array.isArray(value)) {
      value.forEach(ie => {
        unique.add(ie)
      })
    } else {
      unique.add(value)
    }
  })

  return Array.from(unique)
}

const sort = (arr) => {
  const prioritizedItems = ['US Nationwide', 'International']

  arr.sort();

  prioritizedItems.forEach((item) => {
    if (!arr.includes(item)) {
      return
    }

    move(arr, item, 0)
  })

  return arr;
}

const move = (arr, item, to) => {
  let from = arr.indexOf(item)

  let numberOfDeletedElm = 1

  const [elm] = arr.splice(from, numberOfDeletedElm)

  numberOfDeletedElm = 0

  arr.splice(to, numberOfDeletedElm, elm)
}

const ScholarshipsFiltersValues = types
  .model('ScholarshipFilterValues', {
    organization:	types.optional(types.array(types.string), []),
    value: types.optional(types.array(types.string), []),
    applicableTraining: types.optional(types.array(types.string), []),
    eligibilityCriteria: types.optional(types.array(types.string), []),
    location: types.optional(types.array(types.string), []),
  })

const Scholarship = types
  .model('Scholarship', {
    id: '',
    title: '',
    organization:	'',
    value: 0,
    numberAvailable: 0,
    applicableTraining: types.optional(types.array(types.string), []),
    eligibilityCriteria: types.optional(types.array(types.string), []),
    location: types.optional(types.array(types.string), []),
    applicationDeadline: '',
    link: '',
    purpose: '',
    eligibility: '',
  })

const ScholarshipStore = types
  .model('ScholarshipStore', {
    scholarships: types.optional(types.array(Scholarship), []),
    areScholarshipsLoading: true,
    scholarshipsFilters: ScholarshipsFiltersValues,
  })
  .views(self => ({
  }))
  .actions(self => ({
    getScholarships: flow(function*() {
      self.areScholarshipsLoading = true
      try {
        const response = yield axios.get(paths.scholarshipPath())
        self.scholarships = response.data

        const applicableTraining = filterUnique(self.scholarships, 'applicableTraining')
        const eligibilityCriteria = filterUnique(self.scholarships, 'eligibilityCriteria')
        const location = filterUnique(self.scholarships, 'location')
        const organization = filterUnique(self.scholarships, 'organization')

        const filters = {
          applicableTraining: baseApplicableTrainingList.filter(value => applicableTraining.includes(value)),
          eligibilityCriteria: baseEligibilityCriteriaList.filter(value => eligibilityCriteria.includes(value)),
          location: sort(location),
          organization: organization.sort(),
        }
        self.scholarshipsFilters = filters

      } catch (error) {
        console.log(error)
      }
      self.areScholarshipsLoading = false
    }),
  }))

export const scholarshipStore = ScholarshipStore.create({
  scholarshipsFilters: {}
})

window.scholarshipStore = scholarshipStore
