import { flow, types } from 'mobx-state-tree'
import axios from 'axios'
import * as paths from 'utils/path-helpers/api'

const ServiceInfo = types
  .model('ServiceInfo', {
    content: '',
    id: '',
    type: '',
  })

const Service = types
  .model('Service', {
    id: types.string,
    title: types.string,
    imageUrl: types.string,
    description: types.string,
    calendlyLinkMember: types.string,
    calendlyLinkMemberInfo: types.string,
    calendlyLinkNonMember: types.string,
    calendlyLinkNonMemberInfo: types.string,
    info: types.optional(ServiceInfo, {})
  })

const Category = types
  .model('Category', {
    id: types.string,
    title: types.string,
    imageUrl: types.string,
    description: types.string,
    services: types.optional(types.array(Service), []),
  })

const ConsultationsStore = types
  .model('ConsultationsStore', {
    categories: types.optional(types.array(Category), []),
    services: types.optional(types.array(Service), []),
    areCategoriesLoading: true,
    areServicesLoading: true,
  })
  .actions(self => ({
    getCategories: flow(function*() {
      self.areCategoriesLoading = true
      try {
        const response = yield axios.get(paths.categoriesPath());

        self.categories = response.data;
      } catch (error) {
        console.log(error)
      }
      self.areCategoriesLoading = false
    }),
    getServices: flow(function* () {
      self.areServicesLoading = true
      try {
        const response = yield axios.get(paths.servicesPath());

        self.services = response.data;
      } catch (error) {
        console.log(error)
      }
      self.areServicesLoading = false
    }),
    getServicesByCategoryId: (id) => {
      if (self.categories.length) {
        return self.categories.find(category => category.id === id).services
      }

      return [];
    },
    getActiveCategoryTitleById: (id) => {
      if (self.categories.length) {
        return self.categories.find(category => category.id === id).title
      }

      return '';
    },
  }))

export const consultationsStore = ConsultationsStore.create()
