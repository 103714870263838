import React from 'react'
import { Button, Form, DatePicker, Checkbox, Radio, TreeSelect, InputNumber, Input, PlaneLoader, Popover } from 'components'
import { surveyContainerStyles } from './styles.module.scss'
import { observer, inject } from 'mobx-react'
import { isMoment } from 'moment'
import { QuestionCircleOutlined } from '@ant-design/icons'

const rules = [
  {
    required: true,
    message: 'This field is required',
  }
]

const returnTreeNodes = options => {
  return options.map(option => (
    <TreeSelect.TreeNode
      value={option.value}
      title={option.value}
      key={option.value}
    >
      {returnTreeNodes(option.options)}
    </TreeSelect.TreeNode>
  ))
}

const returnRenderComponent = question => {
  if (question.renderType === 'input') {

    if (question.type === 'string') {
      return (
        <Input />
      )

    } if (question.type === 'number') {
      return (
        <InputNumber />
      )

    } if (question.type === 'date') {
      return (
        <DatePicker />
      )
    }

  }

  if (question.renderType === 'radio') {
    return (
      <Radio.Group buttonStyle="solid">
        {question.options.map(option => (
          <Radio.Button key={option.value} value={option.value}>{option.value}</Radio.Button>
        ))}
      </Radio.Group>
    )
  }

  if (question.renderType === 'checkbox') {
    return (
      <Checkbox.Group>
        {question.options.map(option => (
          <>
            <Checkbox key={option.value} value={option.value}>{option.value}</Checkbox>
            <br/>
          </>
        ))}
      </Checkbox.Group>
    )
  }

  if (question.renderType === 'select') {
    return (
      <TreeSelect
        treeDefaultExpandAll
        multiple={question.type === 'array'}
        treeCheckable={question.type === 'array'}
        showCheckedStrategy={TreeSelect.SHOW_CHILD}
      >
        {returnTreeNodes(question.options)}
      </TreeSelect>
    )
  }

}

const returnSurveyQuestion = question => {
  return (
    <span className="survey-question-title">
      {question.title}
        {question.annotation ?
          (
            <Popover trigger={['click', 'hover']}  content={<div className="survey-question-annotation-content" dangerouslySetInnerHTML={{__html: question.annotation}}/>}>
              <QuestionCircleOutlined />
            </Popover>
          ) : (
            null
          )
        }
    </span>
  )
}

const SurveyContainer = props => {
  const onFormSubmit = values => {
    if (props.surveyStore.isSurveyAnswerSaving) return

    const answers = []
    Object.keys(values).forEach(questionId => {
      const value = values[questionId]

      answers.push({
        questionId,
        values: Array.isArray(value) ? value : isMoment(value) ? [ value.format() ] : [ value ],
      })

    })

    const payload = {
      unitId: props.surveyStore.unit.id,
      answers
    }

    props.surveyStore.saveSurveyAnswer(payload)
  }

  return (
    <div className={surveyContainerStyles}>
      <PlaneLoader loading={props.surveyStore.isSQSkeletonVisible}>
        <div className="survey-wrapper">
          <Form onFinish={onFormSubmit}>
            <h3>{props.surveyStore.unitName}</h3>
            {props.surveyStore.unit.questions.map(question => (
              <Form.Item label={returnSurveyQuestion(question)} key={question.id} name={question.id} rules={rules} validateTrigger="onBlur">
                {returnRenderComponent(question)}
              </Form.Item>
            ))}
            <div className="survey-next-button-wrapper">
              <Button loading={props.surveyStore.isSurveyAnswerSaving} type="primary" htmlType="submit">
                Next
              </Button>
            </div>
          </Form>
        </div>
      </PlaneLoader>
    </div>
  )
}

export default inject('surveyStore')(observer (SurveyContainer))
