import React, { useState, useEffect } from 'react'
import { accountTileGenericContainerStyles, accountSubscriptionsStyles } from './styles.module.scss'
import { Breadcrumb, Button, PlaneLoader, Steps, Link, Input, Form } from 'components'
import * as APP_PATHS from 'utils/path-helpers/app'
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js'
import { observer, inject } from 'mobx-react'
import classNames from 'classnames'
import ProductContainer from './ProductContainer'

const priceId = 'price_1H5nO1JjY65cNy4BOP4TBwI1'

const CARD_OPTIONS = {
  iconStyle: 'solid',
  style: {
    base: {
      fontSize: '16px',
      color: '#424770',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      iconColor: '#ffc7ee',
      color: '#ffc7ee',
    },
  },
}

const SubscriptionContainer = props => {
  const stripe = useStripe()
  const elements = useElements()

  const [ selectedSubscription, setSelectedSubscription ] = useState({})
  const [ activeStep, setActiveStep ] = useState(0)
  const [ isFormDisabled, setIsFormDisabled ] = useState(false)

  useEffect(() => {
    props.userDataStore.getSubscriptions()
  }, [])

  useEffect(() => {
    setSelectedSubscription(props.userDataStore.activeSubscriptionObject)
  }, [props.userDataStore.activeSubscriptionObject])

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (!stripe || !elements || isFormDisabled) return
    setIsFormDisabled(true)
    if (!props.userDataStore.user.stripeCustomerId) await props.userDataStore.createStripeCustomer()

    const onAfterPayment = () => {
        setActiveStep(3)
        props.userDataStore.setUpdateSubscriptionLoading(false)
        props.userDataStore.getCurrentUser()
    }

    if (props.userDataStore.user.stripeSubscriptionId) {
      try {
        await props.userDataStore.updateSubscription({
          subscriptionId: props.userDataStore.user.stripeSubscriptionId,
          priceId: selectedSubscription.price.id,
        })

        onAfterPayment()
      } catch (e) {}

      setIsFormDisabled(false)
      return
    }

    const cardElement = elements.getElement(CardElement)

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    })

    if (error) {
      console.log('[createPaymentMethod error]', error)
      setIsFormDisabled(false)
      return
    }
    const paymentMethodId = paymentMethod.id

//    i'm not sure that we can afford this flow ATM
//      if (latestInvoicePaymentIntentStatus === 'requires_payment_method') {
//        const invoiceId = localStorage.getItem('latestInvoiceId');
//        props.userDataStore.retryInvoice({
//          customerId,
//          paymentMethodId,
//          invoiceId,
//          priceId,
//        });
//      } else {

    try {
      await props.userDataStore.createSubscription({
        customerId: props.userDataStore.user.stripeCustomerId,
        priceId: selectedSubscription.price.id,
        paymentMethodId,
        couponId: props.userDataStore.couponId,
      })
      onAfterPayment()
    } catch (e) {
      console.log(e)
    }
    setIsFormDisabled(false)
  }

  const onProductSelect = product => {
    setSelectedSubscription(product)
    setActiveStep(1)
  }

  const onCouponEnterPress = (event) => {
    event.preventDefault()
    props.userDataStore.checkCoupon(event.target.value.trim())
  }

  const onCheckCoupon = (value) => {
    if (value) {
      props.userDataStore.checkCoupon(value.trim())
    }
  }

  const renderProductsStep = (products) => activeStep === 0 ? (
    <div className="account-subscription-products-wrapper">
      {renderProducts(products)}
    </div>
  ) : null

  const renderProducts = (products) => products.map(product => {
    if (!props.userDataStore.activeSubscriptionObject) return null
    if (!selectedSubscription) return null
    if (!product.active) return null

    const isProductActive = props.userDataStore.activeSubscriptionObject.metadata.type === product.metadata.type

    const panelClass = classNames({
      'account-subscription-product': true,
      'account-subscription-product-highlighted': selectedSubscription.id === product.id,
    })

    return (
      <ProductContainer
        key={product.id}
        product={product}
        className={panelClass}
        isProductActive={isProductActive}
        onProductSelect={onProductSelect}
      />
    )
  })

  const returnCardInput = () => {
    if (activeStep !== 1) return null
    if (!selectedSubscription) return null
    if (!props.userDataStore.activeSubscriptionObject) return null
    if (props.userDataStore.activeSubscriptionObject.id === selectedSubscription.id) return null

    return (
      <>
        <div className="payment-product-wrapper">
          <ProductContainer
            hasAction={false}
            className="payment-product"
            coupon={props.userDataStore.user.coupon}
            product={selectedSubscription}
            onProductSelect={onProductSelect}
          />
        </div>
      <form onSubmit={handleSubmit} className="account-payment-product">
        {selectedSubscription.metadata.type !== 'free' &&
          <>
            <Input.Search
              enterButton="Apply"
              placeholder="Promo code"
              disabled={props.userDataStore.user.coupon.id}
              loading={props.userDataStore.isCheckCouponInProgress}
              onPressEnter={onCouponEnterPress}
              onSearch={onCheckCoupon}
            />

            <CardElement options={CARD_OPTIONS}/>
          </>
        }

        <Button htmlType="submit" disabled={!stripe || isFormDisabled}>
          Confirm order
        </Button>
      </form>
      </>
    )
  }

  const returnConfirmation = () => activeStep === 3 ? (
    <div style={{ textAlign: 'center' }}>
      <h3>Your subscription has been updated!</h3>
      <Button>
        <Link to={APP_PATHS.accountPath()}>
          Back to account
        </Link>
      </Button>
    </div>
  ) : null

  return (
    <div className={accountTileGenericContainerStyles}>
      <Breadcrumb className="account-tile-breadcrumb" label="Back" to={APP_PATHS.accountSubscriptionPath()} />
      <PlaneLoader
        loading={props.userDataStore.isSubscriptionsListLoading || props.userDataStore.isUpdateSubscriptionLoading}
      >
        <div className={accountSubscriptionsStyles}>
          <div className="account-subscription-steps-wrapper">
            <Steps current={activeStep}>
              <Steps.Step title="Subscription" />
              <Steps.Step title="Payment" />
              <Steps.Step title="Confirmation" />
            </Steps>
          </div>
          {renderProductsStep(props.userDataStore.subscriptionsList)}
          {returnCardInput()}
          {returnConfirmation()}
        </div>
      </PlaneLoader>
    </div>
  )
}

export default inject('userDataStore')(observer (SubscriptionContainer))
