import React from 'react'
import { accountTileCenteredContainerStyles } from './styles.module.scss'
import { Button, Input, Form, Breadcrumb } from 'components'
import * as paths from 'utils/path-helpers/app'
import { observer, inject } from 'mobx-react'

const passwordRules = [
  {
    min: 8,
    message: 'Min pass length == 8',
  },
  {
    pattern: new RegExp('[0-9]'),
    message: 'No digits',
  },
  {
    pattern: new RegExp('[A-Z]'),
    message: 'No uppercase',
  },
  {
    pattern: new RegExp('[a-z]'),
    message: 'No lowercase',
  },
  {
    required: true,
    message: 'Please provide your password',
  },
]

const confirmPasswordRules = [
  {
    required: true,
    message: 'Passwords must match',
  },
  ({ getFieldValue }) => ({
    validator(rule, value) {
      if (!value || getFieldValue('password') === value) {
        return Promise.resolve()
      }
      return Promise.reject('Passwords do not match')
    },
  }),
]

const initialValues = {
  password: '',
  confirmPassword: '',
}

const PasswordUpdateContainer = props => {
  const onFormSubmit = values => {
    const userPayload = {
      password: values.password,
    }

    props.userDataStore.updatePassword(userPayload)
  }

  return (
    <div className={accountTileCenteredContainerStyles}>
      <Breadcrumb className="account-tile-breadcrumb" label="Back" to={paths.accountPath()} />
      <div className="account-tile-form-wrapper">
        <Form id="password-update-form" onFinish={onFormSubmit} initialValues={initialValues}>
          <Form.Item label="Password" name="password" rules={passwordRules}>
            <Input.Password placeholder="Enter" maxLength={50} />
          </Form.Item>
          <Form.Item label="Confirm password" name="confirmPassword" rules={confirmPasswordRules}>
            <Input.Password placeholder="Enter" maxLength={50} />
          </Form.Item>
        </Form>
        <Button htmlType="submit" form="password-update-form" type="primary" className="register-container-button" >
          Update
        </Button>
      </div>
    </div>
  )
}

export default inject('userDataStore')(observer (PasswordUpdateContainer))
