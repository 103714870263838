import React from 'react'

class SocialAuthContainer extends React.Component {
  componentDidMount() {
    var match,
      pl = /\+/g, // Regex for replacing addition symbol with a space
      search = /([^&=]+)=?([^&]*)/g,
      decode = function(s) {
        return decodeURIComponent(s.replace(pl, ' '))
      },
      query = window.location.search.substring(1)

    var urlParams = {}
    while ((match = search.exec(query))) urlParams[decode(match[1])] = decode(match[2])
    document.socialLoginData = urlParams
    window.close()
  }

  render() {
    return null
  }
}

export default SocialAuthContainer
