// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles-module__surveyContainerStyles___3UeUl{align-items:center;display:flex;justify-content:center;padding:20px 0;height:100%}.styles-module__surveyContainerStyles___3UeUl .survey-wrapper{max-width:450px;width:100%;height:100%}.styles-module__surveyContainerStyles___3UeUl .survey-wrapper .survey-question-title .anticon{margin-left:5px}.styles-module__surveyContainerStyles___3UeUl .survey-wrapper .survey-next-button-wrapper{align-items:center;display:flex;justify-content:center;width:100%}.styles-module__surveyContainerStyles___3UeUl .survey-wrapper h3{text-align:center}.survey-question-annotation-content p:last-child{margin-bottom:0}\n", ""]);
// Exports
exports.locals = {
	"surveyContainerStyles": "styles-module__surveyContainerStyles___3UeUl"
};
module.exports = exports;
