// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles-module__consultationsContainerStyles___3wY53{width:100%;flex-grow:1}.styles-module__consultationsContainerStyles___3wY53 .consultations-wrapper{display:grid;grid-gap:20px;grid-template-columns:repeat(auto-fill, minmax(240px, 1fr));grid-auto-rows:400px}.styles-module__consultationsContainerStyles___3wY53 .consultations-wrapper .ant-card-actions span{justify-content:center}.styles-module__consultationsContainerStyles___3wY53 .consulting-view-title{text-align:center;margin-bottom:20px;width:100%;line-height:36px}.styles-module__consultationsContainerStyles___3wY53 .consultations-breadcrumbs-wrapper{display:flex;flex-direction:row;align-items:center;margin-bottom:20px}.styles-module__consultationsContainerStyles___3wY53 .consultations-breadcrumbs-wrapper .ant-breadcrumb{padding-left:10px}.styles-module__consultationsContainerStyles___3wY53 .ant-card-actions .ant-btn a{color:#fff}.styles-module__consultationsContainerStyles___3wY53 .ant-card-actions .ant-btn a:hover{color:#fff}.styles-module__consultationsContainerStyles___3wY53 .ant-select{position:absolute;bottom:56px;left:10%;width:80%}.styles-module__consultationsContainerStyles___3wY53 .ant-select input{font-size:16px}.service-container-schedule-modal .ant-modal-body{padding:0}.service-container-schedule-modal iframe{border:none;width:100%;height:65vh}\n", ""]);
// Exports
exports.locals = {
	"consultationsContainerStyles": "styles-module__consultationsContainerStyles___3wY53"
};
module.exports = exports;
