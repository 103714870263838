import React from 'react'
import { TreeSelect } from 'antd'
import './styles.scss'

const PPTreeSelect = props => (
  <TreeSelect {...props} className={`custom-tree-select ${props.className || ''}`} />
)

export default PPTreeSelect

