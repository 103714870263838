import history from 'history/history'
import * as paths from 'utils/path-helpers/app'

export const goBackInHistory = () => history.goBack()
export const navigateTo = path => history.push(path)

export const navigateToLogin = () => navigateTo(paths.authPath())
export const navigateToRegistration = () => navigateTo(paths.registrationPath())
export const navigateToForgotPassword = () => navigateTo(paths.authPasswordPath())
export const navigateToConfirmation = () => navigateTo(paths.confirmationPath())

export const navigateToApp = () => navigateTo(paths.appPath())
export const navigateToCareer = () => navigateTo(paths.careerPath())
export const navigateToTimelineAssistant = () => navigateTo(paths.timelinePath())
export const navigateToPathways = () => navigateTo(paths.pathwaysPath())

export const navigateToSubscriptions = () => navigateTo(paths.accountSubscriptionPath())
export const navigateToUpgradeSubscriptions = () => navigateTo(paths.upgradeSubscriptionPath())
export const navigateToDiscountSubscriptions = () => navigateTo(paths.discountSubscriptionPath())

