// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles-module__resourcesContainerStyles___bUvrn{width:100%;flex-grow:1}.styles-module__resourcesContainerStyles___bUvrn .resources-wrapper{display:grid;grid-gap:20px;grid-template-columns:repeat(auto-fill, minmax(240px, 1fr));grid-auto-rows:400px}.styles-module__resourcesContainerStyles___bUvrn .resources-wrapper .ant-card-actions a{color:#ffffff}.styles-module__resourcesContainerStyles___bUvrn .resources-wrapper .ant-card-actions a:hover{color:#ffffff}\n", ""]);
// Exports
exports.locals = {
	"resourcesContainerStyles": "styles-module__resourcesContainerStyles___bUvrn"
};
module.exports = exports;
