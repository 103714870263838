import React, { useEffect, useMemo, useState } from 'react'
import { scholarshipsContainerStyles } from './styles.module.scss'
import {
  PlaneLoader,
  Table,
  Tag,
  Input,
} from 'components'
import { observer, inject } from 'mobx-react'
import { DownOutlined, UpOutlined, LinkOutlined } from '@ant-design/icons'

const numberComparator = (a, b) => (a < b ? -1 : 1)
const stringComparator = (a, b) => (a.toLowerCase() > b.toLowerCase() ? -1 : 1)
const dateComparator = (a, b) => (new Date(a) > new Date(b) ? -1 : 1)

const customExpandIcon = props => (
  <div style={{ cursor: 'pointer' }} onClick={e => { props.onExpand(props.record, e) }}>
    {props.expanded ? <UpOutlined /> : <DownOutlined />}
  </div>
)

const expandedRowRender = record => (
  <div className="scholarships-expanded-row">
    <div className="scholarships-expanded-row-section">
      <div className="scholarships-expanded-row-title">
        Purpose
      </div>
      <div className="scholarships-expanded-row-text">
        {record.purpose}
      </div>
    </div>

    <div className="scholarships-expanded-row-section">
      <div className="scholarships-expanded-row-title">
        Eligibility
      </div>
      <div className="scholarships-expanded-row-text">
        {record.eligibility}
      </div>
    </div>
  </div>
)

const linkCellRender = value => (
  <div className="scholarships-link-cell">
    <a target="_blank" href={value}>
      <LinkOutlined />
    </a>
  </div>
)

const tagCellRender = value => (
  <div className="scholarships-tag-cell">
    {value.map(e => <Tag key={e} children={e} />)}
  </div>
)

const textCellRender = value => (
  value.reduce((accum, current) => (accum ? `${accum}, ${current}` : current), '')
)

const priceCellRender = value => `$${value.toLocaleString('en-US')}`

const ScholarshipsContainer = props => {
  const [searchValue, setSearchValue] = useState('')
  const [filteredScholarships, setFilteredScholarships] = useState(props.scholarshipStore.scholarships)
  const {
    organization,
    applicableTraining,
    eligibilityCriteria,
    location,
  } = props.scholarshipStore.scholarshipsFilters

  const getFilteredScholarships = () => {
    const filteringFields = ['title', 'organization']

    return props.scholarshipStore.scholarships.filter(scholarship => (
      filteringFields.some(field => scholarship[field].toLowerCase().includes(searchValue.toLowerCase()))
    ))
  }

  useEffect(() => {
    props.scholarshipStore.getScholarships()
  }, [])

  useEffect(() => {
    if (!searchValue) {
      setFilteredScholarships(props.scholarshipStore.scholarships)
      return
    }

    const filteredArray = getFilteredScholarships()

    setFilteredScholarships(filteredArray)
  }, [searchValue])

  const columns = useMemo(() => {
    return [
      {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
        sortDirections: ['descend', 'ascend'],
        sorter: (a, b) => (stringComparator(a.title, b.title)),
      },
      {
        title: 'Organization',
        dataIndex: 'organization',
        key: 'organization',
        sortDirections: ['descend', 'ascend'],
        sorter: (a, b) => (stringComparator(a.organization, b.organization)),
        onFilter: (value, record) => record.organization === value,
        filters: organization.map(e => ({ text: e, value: e })),
      },
      {
        title: 'Value',
        dataIndex: 'value',
        key: 'value',
        sortDirections: ['descend', 'ascend'],
        sorter: (a, b) => (numberComparator(a.value, b.value)),
        render: priceCellRender,
      },
      {
        title: 'Available',
        key: 'numberAvailable',
        dataIndex: 'numberAvailable',
        sortDirections: ['descend', 'ascend'],
        sorter: (a, b) => (numberComparator(a.numberAvailable, b.numberAvailable)),
      },
      {
        title: 'Applicable Training',
        key: 'applicableTraining',
        dataIndex: 'applicableTraining',
        onFilter: (value, record) => record.applicableTraining.includes(value),
        filters: applicableTraining.map(e => ({ text: e, value: e })),
        render: textCellRender,
      },
      {
        title: 'Eligibility Criteria',
        key: 'eligibilityCriteria',
        dataIndex: 'eligibilityCriteria',
        render: tagCellRender,
        onFilter: (value, record) => record.eligibilityCriteria.includes(value),
        filters: eligibilityCriteria.map(e => ({ text: e, value: e })),
      },
      {
        title: 'Location',
        key: 'location',
        dataIndex: 'location',
        onFilter: (value, record) => record.location.includes(value),
        filters: location.map(e => ({ text: e, value: e })),
        render: textCellRender,
      },
      {
        title: 'Application Deadline',
        key: 'applicationDeadline',
        dataIndex: 'applicationDeadline',
        sortDirections: ['descend', 'ascend'],
        sorter: (a, b) => (dateComparator(a.applicationDeadline, b.applicationDeadline)),
        render: value => new Date(value).toLocaleDateString(),
      },
      {
        title: 'Link',
        key: 'link',
        dataIndex: 'link',
        render: linkCellRender,
      },
    ]
  }, [organization.length, applicableTraining.length, eligibilityCriteria.length, location.length])

  const onSearchChange = e => {
    setSearchValue(e.target.value)
  }

  return (
    <div className={scholarshipsContainerStyles}>
      <PlaneLoader loading={props.scholarshipStore.areScholarshipsLoading}>
        <>
          <Input
            allowClear
            className="scholarships-search-input"
            placeholder="Search by Title or Organization"
            onChange={onSearchChange}
          />
          <Table
            dataSource={filteredScholarships}
            columns={columns}
            expandIcon={customExpandIcon}
            rowKey="id"
            bordered
            expandable={{
              expandedRowRender,
              rowExpandable: record => record.purpose || record.eligibility
            }}
            pagination={{
              pageSize: 10,
            }}
          />
        </>
      </PlaneLoader>
    </div>
  )
}

export default inject('scholarshipStore')( observer (ScholarshipsContainer))
