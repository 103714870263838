import React from 'react'
import { Button } from 'antd'
import './styles.scss'

const PPButton = ({ shape = 'round', type = "primary", ...props }) => (
  <Button {...props} shape={shape} type={type} className={`custom-button ${props.className || ''}`} />
)

export default PPButton

