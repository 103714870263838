import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Card, Typography, Tooltip } from 'components'
import { consultingCategoryPath } from 'utils/path-helpers/app'

const Description = ({ children }) => (
  <Typography.Paragraph ellipsis={{ rows: 4 }}>{children}</Typography.Paragraph>
)

const CategoryContainer = ({ category }) => (
  <Card
    key={category.id}
    cover={<img alt="image" src={category.imageUrl}/>}
    actions={[
      <Button type="primary">
        <Link to={consultingCategoryPath(category.id)}>
          Learn More
        </Link>
      </Button>
    ]}
  >
    <Card.Meta
      title={<Tooltip placement="topLeft" title={category.title}>{category.title}</Tooltip>}
      description={<Description>{category.description}</Description>}
    />
  </Card>
)

export default CategoryContainer;
