import React from 'react'

import { ReactComponent as Contrast } from '@coreui/icons/svg/free/cil-contrast.svg'
import { ReactComponent as CheckCircle } from '@coreui/icons/svg/free/cil-check-circle.svg'
import { ReactComponent as XCircle } from '@coreui/icons/svg/free/cil-x-circle.svg'
//import { ReactComponent as InfoCircle } from '@coreui/icons/svg/free/cil-info-circle.svg'
import { ReactComponent as ArrowCircleRight } from '@coreui/icons/svg/free/cil-arrow-circle-right.svg'

import Icon from '@ant-design/icons'

const iconsMap = {
  "contrast": Contrast,
  "check-circle": CheckCircle,
  "x-circle": XCircle,
//  "info-circle": InfoCircle,
  "arrow-circle-right": ArrowCircleRight,
}

const PPIcon = ({ name, ...props }) => (
  <Icon component={iconsMap[name] || Contrast} {...props} />
)

export default PPIcon