export const baseApplicableTrainingList = [
  'College Tuition',
  'Flight Training',
  'Airplane',
  'Seaplane',
  'Helicopter',
  'Gyroplane',
  'Glider',
  'A&P',
  'AMP',
  'Tuition',
  'PPL',
  'RPL',
  'SPL',
  'IR',
  'CPL',
  'ATP',
  'CFI',
  'CFII',
  'MEI',
  'Emergency',
  'STOL',
  'Tailwheel',
  'Type Rating',
]

export const baseEligibilityCriteriaList = [
  'US Citizen',
  'Non-US Citizen',
  'Member',
  'Non-Member',
  'Student',
  'Women',
  'Youth',
]
