// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles-module__applicationContainerStyles___1b2iX{min-height:100%;width:100%;display:grid;grid-template-columns:240px auto;grid-template-rows:110px auto;transition:0.5s}.styles-module__applicationContainerStyles___1b2iX>header{grid-column:1 / -1;grid-row:1 / 1}.styles-module__applicationContainerStyles___1b2iX>main{grid-column:2 / -1;grid-row:2 / -1;background-color:#FFF;padding:20px;position:relative}@media (max-width: 950px){.styles-module__applicationContainerStyles___1b2iX{grid-template-columns:auto;grid-template-rows:50px auto}.styles-module__applicationContainerStyles___1b2iX>aside{position:fixed;left:-325px}.styles-module__applicationContainerStyles___1b2iX>aside.visible{left:0}.styles-module__applicationContainerStyles___1b2iX>main{grid-column:1 / -1;grid-row:2 / -1;padding:20px}.styles-module__applicationContainerStyles___1b2iX>header{grid-column:1 / -1}}@media (max-width: 600px){.styles-module__applicationContainerStyles___1b2iX>main{padding:10px}}\n", ""]);
// Exports
exports.locals = {
	"applicationContainerStyles": "styles-module__applicationContainerStyles___1b2iX"
};
module.exports = exports;
