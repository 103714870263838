import { flow, types } from 'mobx-state-tree'
import axios from 'axios'
import * as paths from 'utils/path-helpers/api'

const Resources = types
  .model('Resources', {
    id: '',
    title: '',
    imageUrl: '',
    description: '',
    moreInfoLink: '',
  })

const ResourcesStore = types
  .model('ResourcesStore', {
    resources: types.optional(types.array(Resources), []),
    areResourcesLoading: true,
  })
  .actions(self => ({
    getResources: flow(function*() {
      self.areResourcesLoading = true
      try {
        const response = yield axios.get(paths.resourcesPath());

        self.resources = response.data;
      } catch (error) {
        console.log(error)
      }
      self.areResourcesLoading = false
    }),
  }))

export const resourcesStore = ResourcesStore.create()

window.resourcesStore = resourcesStore
