import React, { useEffect } from 'react'
import { accountTileCenteredContainerStyles } from './styles.module.scss'
import { Breadcrumb } from 'components'
import * as APP_PATHS from 'utils/path-helpers/app'

const ContactUsContainer = props => (
    <div className={accountTileCenteredContainerStyles}>
      <Breadcrumb className="account-tile-breadcrumb" label="Back" to={APP_PATHS.accountPath()} />
      <div>
        <a href="mailto:support@pilotpipeline.com">
          support@pilotpipeline.com
        </a>
      </div>
    </div>
)

export default ContactUsContainer
