import React, { useState } from 'react'
import { inject, observer } from 'mobx-react'
import ReactPlayer from 'react-player'
import { Button, Card, Typography, Tooltip, Modal, Select } from 'components'
import { navigateToSubscriptions } from 'utils/navigation'

const Description = ({ children }) => (
  <Typography.Paragraph ellipsis={{ rows: 4 }}>{children}</Typography.Paragraph>
)

const ServiceContainer = ({ service, ...props }) => {
  const [scheduleLink, setScheduleLink] = useState('')
  const [isAboutModalVisible, setIsAboutModalVisible] = useState(false)
  const [isScheduleModalVisible, setIsScheduleModalVisible] = useState(false)
  const [activeModalConsultationInfo, setConsultationInfo] = useState({})
  const [isMember, setIsMember] = useState(false)

  const getScheduleLinkParams = () => {
    const { email, firstName, lastName } = props.userDataStore.user

    return `&firstName=${firstName}&lastName=${lastName}&email=${email}`
  }

  const getScheduleLinkWithParams = () => {
    return `${scheduleLink}?${getScheduleLinkParams()}`
  }

  const renderCardActionsButtons = () => {
    return [
      <Button key="about" onClick={onModalButtonClick}>
        About
      </Button>,
      <Button key="schedule" onClick={onCalendarButtonClick}>
        Schedule
      </Button>,
    ]
  }

  const renderActionContent = (info) => {
    const { type, content } = info
    if (type === 'text') {
      return (
        <div>
          {content}
        </div>
      )
    }

    if (type === 'html') {
      return (
        <div className="content-type-html-content" dangerouslySetInnerHTML={{ __html: content }}/>
      )
    }

    if (type === 'image') {
      return (
        <img alt="" style={{ width: '100%' }} src={content}/>
      )
    }

    if (type === 'video') {
      return (
        <ReactPlayer
          width="100%"
          url={content}
        />
      )
    }
  }

  const onCalendarButtonClick = (e) => {
    e.preventDefault()
    setScheduleLink(isMember ? service.calendlyLinkMember : service.calendlyLinkNonMember)

    if (isMember && props.userDataStore.isSubscriptionFree) {
      Modal.confirm({
        title: 'Premium Account Required',
        content: 'In order to access Timeline Assistant, you need a Premium account. U.S. Military Veterans receive free access to premium forever. Click below to get started.',
        okText: 'Upgrade',
        cancelText: 'Cancel',
        onOk: navigateToSubscriptions,
      })

      return
    }

    setIsScheduleModalVisible(true)
  }

  const onModalButtonClick = () => {
    setConsultationInfo(service.info)
    setIsAboutModalVisible(true)
  }

  return (
    <>
      <Modal
        visible={isAboutModalVisible}
        footer={[
          <Button
            key="ok"
            type="primary"
            children="OK"
            onClick={() => setIsAboutModalVisible(false)}
          />
        ]}
        destroyOnClose
        closable={false}
        afterClose={() => setConsultationInfo({})}
        onCancel={() => setIsAboutModalVisible(false)}
      >
        {renderActionContent(activeModalConsultationInfo)}
      </Modal>
      <Modal
        className="service-container-schedule-modal"
        width="800px"
        title="Schedule Appointment"
        visible={isScheduleModalVisible}
        footer={[
          <Button
            key="ok"
            type="primary"
            children="OK"
            onClick={() => setIsScheduleModalVisible(false)}
          />
        ]}
        destroyOnClose
        closable={false}
        onCancel={() => setIsScheduleModalVisible(false)}
      >
        <iframe src={getScheduleLinkWithParams()} />
      </Modal>
      <Card
        key={service.id}
        cover={<img alt="image" src={service.imageUrl}/>}
        actions={renderCardActionsButtons()}
      >
        <Card.Meta
          title={<Tooltip placement="topLeft" title={service.title}>{service.title}</Tooltip>}
          description={
            <>
            <Description>{service.description}</Description>
            <Select defaultValue={isMember} onChange={(value) => setIsMember(value)}>
              <Select.Option value={true}>
                {service.calendlyLinkMemberInfo}
              </Select.Option>
              <Select.Option value={false}>
                {service.calendlyLinkNonMemberInfo}
              </Select.Option>
            </Select>
            </>
          }
        />
      </Card>
    </>
  )
}

export default inject('userDataStore')(observer(ServiceContainer));
