import React from 'react'
import { pathwaySelectionContainerStyles } from './styles.module.scss'
import { Collapse, Tag, Timeline, List, Avatar, Modal, Button, PlaneLoader } from 'components'
import { observer, inject } from 'mobx-react'
import classNames from 'classnames'
import { navigateToCareer } from 'utils/navigation'
import { isFlexAirInstance } from 'constants/instances'

const returnModuleSteps = module => module.steps.map(step => (
  <Timeline.Item key={step.title}>
    {step.title}
  </Timeline.Item>
))

const returnTags = tags => tags.map(tag => (
  <Tag key={tag}>
    {tag}
  </Tag>
))

const returnTCEStat = (label, value) => (
  <div className="pathway-container-path-tce-stat">
    <div className="pathway-container-path-tce-stat-label">{label}</div>
    <div className="pathway-container-path-tce-stat-value">{value}</div>
  </div>
)

const returnPathsTitle = path => (
  <div className="pathway-container-path-header">
    <div className="pathway-container-path-title-wrapper">
      <h2 className="pathway-container-path-title">
        {path.title}
      </h2>
      <span className="pathway-container-path-tags">
        {returnTags(path.tags)}
      </span>
    </div>
    <div className="pathway-container-tce-wrapper">
      {returnTCEStat('Time', path.inDevelopment ? '-' : path.readableAverageTime)}
      {returnTCEStat('Cost', path.inDevelopment ? '-' : path.readableAverageCost)}
      {returnTCEStat('Earnings', path.inDevelopment ? '-' : path.readablePredictedEarnings)}
    </div>
  </div>
)

const returnModuleModal = module => (
  <Timeline>
    {returnModuleSteps(module)}
  </Timeline>
)

const moduleItemRenderer = module => (
  <List.Item key={module.id}>
    <div className="pathway-container-module-wrapper">
      <Avatar
        size={150}
        src={module.icon}
        className="pathway-container-module-avatar"
      />
      <div className="pathway-container-module-data-container">
        <div className="pathway-container-module-title">
          <h3>{module.title}</h3>
          <Button
            type="primary"
            size="small"
            onClick={() => Modal.info({
              title: `Steps to complete ${module.title}`,
              content: returnModuleModal(module),
              icon: null,
              maskClosable: true,
              closable: true,
              okButtonProps: {
                shape: 'round'
              },
            })}
          >
            View steps
          </Button>
        </div>
        <div className="pathway-container-tce-wrapper">
          {returnTCEStat('Time', module.readableAverageTime)}
          {returnTCEStat('Cost', module.readableAverageCost)}
          {returnTCEStat('Earnings', module.readablePredictedEarnings)}
        </div>
        <div className="pathway-container-module-description">
          {module.description}
        </div>
      </div>
    </div>
  </List.Item>
)

const onSelectPathClick = (e, path, isPathActive) => {
  e.stopPropagation()
  if (isPathActive) {
    navigateToCareer()
  } else {
    path.selectPath()
  }
}

const returnSelectPathButton = (path, isPathActive) => path.inDevelopment ? (
  null
) : (
  <Button
    type="primary"
    color="primary"
    onClick={e => onSelectPathClick(e, path, isPathActive)}
    loading={path.isPathSelectionInProgress}
  >
    {isPathActive ? 'Navigate to path' : 'Select path'}
  </Button>
)

const returnPathwayPanels = props => props.pathwaysSelectionStore.paths.map(path => {
  if (isFlexAirInstance) {
    if (props.preferred && !path.preferred) return null;
    if (!props.preferred && path.preferred) return null;
  }

  const isPathActive = (props.careerStore.activePath && (props.careerStore.activePath.id === path.id))

  const panelClass = classNames({
    'pathways-container-active-panel': isPathActive,
    'pathways-container-under-construction-panel': path.inDevelopment,
  })

  return (
    <Collapse.Panel
      header={returnPathsTitle(path)}
      key={path.id}
      extra={returnSelectPathButton(path, isPathActive)}
      className={panelClass}
    >
      <List
        itemLayout="vertical"
        size="large"
        dataSource={path.modules}
        renderItem={moduleItemRenderer}
      />
    </Collapse.Panel>
  )
})

const PathwaySelectionContainer = props => {

  return (
    <div className={pathwaySelectionContainerStyles}>
      <PlaneLoader loading={props.pathwaysSelectionStore.arePathsLoading}>
        <Collapse>
          {returnPathwayPanels(props)}
        </Collapse>
      </PlaneLoader>
    </div>
  )
}

export default inject('careerStore')(inject('pathwaysSelectionStore')(observer (PathwaySelectionContainer)))
