import React from 'react'
import { Input } from 'antd'
import './styles.scss'

const PPInputPassword = props => (
  <Input.Password {...props} className={`custom-input ${props.className || ''}`} />
)

export default PPInputPassword

