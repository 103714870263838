import React from 'react'
import { centeredLayoutStyles } from './styles.module.scss'
import { navigateTo } from 'utils/navigation'

const CenteredLayout = props => (
  <div className={centeredLayoutStyles}>
    {props.children}
  </div>
)

export default CenteredLayout

