const refreshName = 'refresh'
const accessName = 'access'

const setToken = (type, token) => window.localStorage.setItem(type, token)
const getToken = type => window.localStorage.getItem(type)
const removeToken = type => window.localStorage.removeItem(type)

export const refreshToken = {
  set: token => setToken(refreshName, token),
  get: () => getToken(refreshName),
  remove: () => removeToken(refreshName),
}

export const accessToken = {
  set: token => setToken(accessName, token),
  get: () => getToken(accessName),
  remove: () => removeToken(accessName),
}

export const openSocialLoginWindow = (provider, handler, errorHandler) => {
  const windowFeatures = 'menubar=no,location=yes,resizable=no,scrollbars=no,status=no,width=580px,height=450px'
  const redirectURI = `${window.location.origin}/technical/social-auth`

  const providers = {
    govx: {
      loginUrl: `https://auth.govx.com/oauth/authorize?client_id=3d3cd1ae-1fe6-ea11-80c6-005056b8e1cc&scope=user_profile+verification&redirect_uri=${redirectURI}&response_type=code&display=popup&goTo=login`,
      windowName: `govx-social-login`,
      errorMessage: "Login failed"
    },
  }

  const providerData = providers[provider]

  const popupWindow = window.open(providerData.loginUrl, providerData.windowName, windowFeatures)
  const timer = setInterval(() => {
    if (popupWindow.closed) {
      clearInterval(timer)
      if (popupWindow.document) {
        const socialData = popupWindow.document.socialLoginData
        handler(socialData, redirectURI)
      } else {
        if (errorHandler) errorHandler(providerData.errorMessage)
      }
    }
  }, 300)
}
