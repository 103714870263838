import { types, flow } from 'mobx-state-tree'
import moment from 'moment'
import * as apiPaths from 'utils/path-helpers/api'
import { message } from 'utils/notifications'
import { accessToken, refreshToken } from 'utils/auth'
import { navigateToConfirmation, navigateToApp, navigateToLogin } from 'utils/navigation'
import axios from 'axios'
import { masterStore, careerStore, pathwaysSelectionStore } from 'stores'
import * as NOTIFICATIONS from 'constants/wordings/notifications'
import LogRocket from 'logrocket'
import { SUBSCRIPTIONS_TYPES, COUPON_DURATION } from 'constants/subscriptions'

const initialUser = {
  id: '',
  email: '',
  firstName: '',
  lastName: '',
  birthDate: '',
  avatar: '',
  retirementAge: 1,
  jsonStorage: {},
  coupon: {}
}

const Storage = types
  .model({
    isTimelineDemoFinished: types.optional(types.boolean, false, [undefined, null]),
    isPlannerDemoFinished: types.optional(types.boolean, false, [undefined, null]),
    isPlannerDemoSkipped: types.optional(types.boolean, false, [undefined, null]),
    isTimelineDemoSkipped: types.optional(types.boolean, false, [undefined, null]),
  })

const Coupon = types
  .model({
    id: types.union(types.undefined, types.string),
    percent_off: 0,
    metadata: types.frozen({}),
    duration: types.union(types.undefined, types.string),
    trialEnd: types.union(types.undefined, types.number),
    duration_in_months: types.union(types.undefined, types.null, types.number)
  })
  .views(self => ({
    get isCouponTrial() {
      return self.metadata.trial === 'true'
    }
  }))

const User = types
  .model({
    id: '',
    email: '',
    firstName: '',
    lastName: '',
    birthDate: '',
    avatar: '',
    retirementAge: 0,
    stripeCustomerId: '',
    stripeSubscriptionId: '',
    subscription: '',
    jsonStorage: Storage,
    coupon: types.optional(Coupon, {}),
  })
  .views(self => ({
    get activeSubscriptionName() {
      return SUBSCRIPTIONS_TYPES(self.subscription)
    },
  }))

const UserDataStore = types
  .model("UserStore", {
    user: User,
    subscriptionsList: types.optional(types.array(types.frozen()), []),
    isSubscriptionsListLoading: true,
    isSubscriptionInfoLoading: true,
    isUpdateSubscriptionLoading: false,
    isLoginInProgress: false,
    isSignupInProgress: false,
    isUserDataLoading: false,
    isShowPlannerDemo: false,
    isShowTimelineDemo: false,
    isCheckCouponInProgress: false,
    subscriptionInfo: types.frozen({}),
  })
  .views(self => ({
    get fullName() {
      return self.user ? `${self.user.firstName} ${self.user.lastName}` : ''
    },
    get couponId() {
      if (!self.user.coupon.id) return

      return self.user.coupon.id
    },
    get initials() {
      return self.user ? `${self.user.firstName[0]}${self.user.lastName[0]}` : ''
    },
    get activeSubscriptionObject() {
      return self.subscriptionsList.find(subs => subs.metadata.type === self.user.subscription)
    },
    get isStripeSubscriptionActive() {
      return !!self.subscriptionInfo.id
    },
    get isSubscriptionFree() {
      return self.user.subscription === 'free'
    },
    get areDemosCompleted() {
      if (self.isSubscriptionFree) {
        return self.user.jsonStorage.isPlannerDemoFinished
      }

      return self.user.jsonStorage.isTimelineDemoFinished && self.user.jsonStorage.isPlannerDemoFinished
    },
    get subscriptionRenewsDate() {
      if (!self.isStripeSubscriptionActive) {
        return 'Forever'
      }

      return new Date(self.subscriptionInfo.current_period_end * 1000).toLocaleDateString()
    },
    get subscriptionPrice() {
      const { discount } = self.subscriptionInfo

      if (!self.isStripeSubscriptionActive) {
        return 'Free'
      }

      let standardPrice = String(self.subscriptionInfo.plan.amount / 100).toLocaleString()

      if (!discount || !discount.coupon) return `$${standardPrice}`
      if (discount.coupon.duration === COUPON_DURATION.FOREVER) return 'Free'

      return `$${standardPrice}`
    },
  }))
  .actions(self => ({
    createUser: flow(function*(user) {
      self.isSignupInProgress = true
      try {
        yield axios.post(apiPaths.usersPath(), user)
        navigateToConfirmation()
      } catch (error) {
        message.error(error.response.data.message)
      }
      self.isSignupInProgress = false
    }),
    loginUser: flow(function*(user) {
      self.isLoginInProgress = true
      try {
        const response = yield axios.post(apiPaths.loginPath(), user)
        accessToken.set(response.data.accessToken)
        refreshToken.set(response.data.refreshToken)
        navigateToApp()
        masterStore.onInit()
      } catch (error) {
        message.error(NOTIFICATIONS.USER_ACCOUNT.LOGIN_INVALID_CREDENTIALS())
      }
      self.isLoginInProgress = false
    }),
    logoutUser: flow(function*() {
      try {
        const jsonStorage = JSON.stringify({
          ...self.user.jsonStorage,
          isPlannerDemoSkipped: false,
          isTimelineDemoSkipped: false,
        })

        yield axios.patch(apiPaths.currentUserPath(), { jsonStorage })
        yield axios.post(apiPaths.logoutPath())
      } finally {
        accessToken.remove()
        refreshToken.remove()
        navigateToLogin()
        self.setUser({ ...initialUser })
      }
    }),
    getCurrentUser: flow(function*() {
      try {
        self.isUserDataLoading = true
        const response = yield axios.get(apiPaths.currentUserPath())
        if (window.env === 'prod') {
          LogRocket.identify(response.data.id, {
            name: `${response.data.firstName} ${response.data.lastName}`,
            email: response.data.email,
            subscription: response.data.subscription,
          })

          if (window.heap) {
            window.heap.identify(response.data.id)
            window.heap.addUserProperties({
              name: `${response.data.firstName} ${response.data.lastName}`,
              email: response.data.email,
              subscription: response.data.subscription,
            })
          }
        }

        self.setUser(response.data)
        self.getUserSubscriptionInfo()
      } catch (error) {
        console.log(error)
      }
      self.isUserDataLoading = false
    }),
    requestPasswordRestore: flow(function*(payload) {
      try {
        yield axios.post(apiPaths.restorePasswordPath(), payload)
        navigateToConfirmation()
      } catch (error) {
        console.log(error)
      }
    }),
    resetPassword: flow(function*(payload) {
      try {
        const { code, password } = payload
        const axiosConfig = {
          url: apiPaths.currentUserPath(),
          method: 'PATCH',
          data: { password },
          headers: {
            'Authorization': `Bearer ${code}`,
          },
        }
        yield axios.request(axiosConfig)
        message.success(NOTIFICATIONS.USER_ACCOUNT.PASSWORD_UPDATE_SUCCESS())
        navigateToLogin()
      } catch (error) {
        console.log(error)
      }
    }),
    updatePassword: flow(function*(payload) {
      try {
        yield axios.patch(apiPaths.currentUserPath(), payload)
        message.success(NOTIFICATIONS.USER_ACCOUNT.PASSWORD_UPDATE_SUCCESS())
      } catch (error) {
        console.log(error)
      }
    }),
    completeTimelineAssistantDemo: function() {
      self.saveInJSONStorage({ isTimelineDemoFinished: true })
    },
    completePlannerDemo: function() {
      self.saveInJSONStorage({ isPlannerDemoFinished: true })
    },
    setSkipPlannerDemo: function(value) {
      self.saveInJSONStorage({ isPlannerDemoSkipped: value })
    },
    setSkipTimelineDemo: function(value) {
      self.saveInJSONStorage({ isTimelineDemoSkipped: value })
    },
    setShowPlannerDemo: function(value) {
      self.isShowPlannerDemo = value
    },
    setShowTimelineDemo: function(value) {
      self.isShowTimelineDemo = value
    },
    setUpdateSubscriptionLoading: function(value) {
      self.isUpdateSubscriptionLoading = value;
    },
    modifyJSONStorageLocally: function(fieldsToMutate) {
      self.user.jsonStorage = Object.assign(self.user.jsonStorage, fieldsToMutate)
    },
    saveInJSONStorage: flow(function*(fieldsToMutate) {
      try {
        const data = {
          ...self.user.jsonStorage,
          ...fieldsToMutate
        }

        const response = yield axios.patch(apiPaths.currentUserPath(), { jsonStorage: JSON.stringify(data) })
        self.user.jsonStorage = JSON.parse(response.data.jsonStorage)
      } catch (error) {
        console.log(error)
      }
    }),
    updateCurrentUser: flow(function*(user) {
      try {
        self.isUserDataLoading = true
        const response = yield axios.patch(apiPaths.currentUserPath(), user)
        self.setUser(response.data)

        careerStore.getDataInitially()
        pathwaysSelectionStore.getPaths()

        message.success(NOTIFICATIONS.USER_ACCOUNT.USER_DATA_UPDATE_SUCCESS())
      } catch (error) {
        console.log(error)
      }
      self.isUserDataLoading = false
    }),
    uploadCurrentUserAvatar: flow(function*(image) {
      try {
        const formData = new FormData()
        formData.append("file", image)

        yield axios.post(apiPaths.avatarUploadPath(), formData)
        self.getCurrentUser()
      } catch (error) {
        console.log(error)
      }
    }),
    getSubscriptions: flow(function*() {
      self.isSubscriptionsListLoading = true
      try {
        let subscriptions = []

        const productsResponse = yield axios.get(apiPaths.productsSubscriptionsPath())
        const pricesResponse = yield axios.get(apiPaths.pricesSubscriptionsPath())

        pricesResponse.data.forEach(price => {
          if (price.active) {
            const product = productsResponse.data.find(prod => prod.id === price.product)
            subscriptions.push({
              ...product,
              price
            })
          }
        })

        self.subscriptionsList = subscriptions.sort((a, b) => a.price.unit_amount > b.price.unit_amount ? 1 : -1)
      } catch (error) {
        message.error(error)
      }
      self.isSubscriptionsListLoading = false
    }),
    getUserSubscriptionInfo: flow(function*() {
      self.isSubscriptionInfoLoading = true

      if (!self.user.stripeSubscriptionId) {
        self.subscriptionInfo = {}
        self.isSubscriptionInfoLoading = false
        return
      }

      try {
        const subscriptionResponse = yield axios.get(apiPaths.subscriptionByIdPath(self.user.stripeSubscriptionId))
        self.subscriptionInfo = subscriptionResponse.data
      } catch (error) {
        message.error(error)
      }

      self.isSubscriptionInfoLoading = false
    }),
    checkCoupon: flow(function* (promocode) {
      self.isCheckCouponInProgress = true
      try {
        const response = yield axios.get(`${apiPaths.subscriptionsCouponsPath()}/${promocode}`)

        self.user.coupon = response.data.coupon

        if (self.user.coupon.isCouponTrial) {
          const response = yield axios.get(`${apiPaths.subscriptionsTrialEndPath()}/${self.user.coupon.id}`)
          self.user.coupon.trialEnd = response.data
        }

        message.success(NOTIFICATIONS.USER_ACCOUNT.COUPON_VERIFIED())
      } catch (error) {
        message.error(error.response.data.message)
      }

      self.isCheckCouponInProgress = false
    }),
    verifyVeteran: flow(function*(data) {
      try {
        yield axios.post(apiPaths.govxVerificationPath(), data)
        message.success(NOTIFICATIONS.USER_ACCOUNT.GOVX_VERIFIED())
        self.getCurrentUser()
      } catch (error) {
        message.error(error.response.data.message)
      }
    }),
    createStripeCustomer: flow(function*() {
      try {
        const response = yield axios.post(apiPaths.customerSubscriptionsPath())
        self.user.stripeCustomerId = response.data.id
      } catch (error) {
        message.error(error.response.data.message)
      }
    }),
    createSubscription: flow(function*(subscription) {
      self.setUpdateSubscriptionLoading(true)
      try {
        yield axios.post(apiPaths.subscriptionsPath(), subscription)
      } catch (error) {
        self.setUpdateSubscriptionLoading(false)
        message.error(error.response.data.message)
        throw error
      }
    }),
    cancelSubscription: flow(function*() {
      try {
        yield axios.post(apiPaths.cancelSubscriptionsPath(), { subscriptionId: self.user.stripeSubscriptionId })
        self.getCurrentUser()
      } catch (error) {
        message.error(error.response.data.message)
      }
    }),
    updateSubscription: flow(function*(subscription) {
      self.setUpdateSubscriptionLoading(true)
      try {
        yield axios.post(apiPaths.updateSubscriptionsPath(), subscription)
      } catch (error) {
        self.setUpdateSubscriptionLoading(false)
        message.error(error.response.data.message)
        throw (error)
      }
    }),
    retryInvoice: flow(function*(data) {
      try {
        yield axios.post(apiPaths.retryInvoicePath(), data)
      } catch (error) {
        message.error(error.response.data.message)
      }
    }),
    setUser: user => {
      const jsonStorage = (typeof user.jsonStorage === 'string') && user.jsonStorage.length ? JSON.parse(user.jsonStorage) : {}
      self.user = {
        ...user,
        jsonStorage,
      }
    },
  }))

export const userDataStore = UserDataStore.create({
  user: { ...initialUser }
})

window.user = userDataStore
