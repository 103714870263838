// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles-module__accountNavigationContainerStyles___3-CuU{width:100%;display:grid;grid-template-columns:280px 1fr;grid-gap:20px}.styles-module__accountNavigationContainerStyles___3-CuU .user-avatar-panel-wrapper{background-color:#ffffff;padding:20px;align-items:center;display:flex;justify-content:center;font-weight:bold;color:#6D6E70;font-size:20px;flex-direction:column}.styles-module__accountNavigationContainerStyles___3-CuU .user-avatar-panel-container{display:flex}.styles-module__accountNavigationContainerStyles___3-CuU .user-avatar-picker-wrapper{position:relative;overflow:hidden;border-radius:50%;cursor:pointer}.styles-module__accountNavigationContainerStyles___3-CuU .user-avatar-picker-wrapper.loader-visible>*{color:transparent;background:linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);background-size:400% 100%;-webkit-animation:ant-skeleton-loading 1.4s ease infinite;animation:ant-skeleton-loading 1.4s ease infinite}.styles-module__accountNavigationContainerStyles___3-CuU .user-avatar-picker-wrapper .user-avatar-picker-shadow{position:absolute;left:0;bottom:-32px;background-color:rgba(0,0,0,0.5);color:#ffffff;width:100%;text-align:center;height:32px;transition:bottom 0.15s}.styles-module__accountNavigationContainerStyles___3-CuU .user-avatar-picker-wrapper>span>*{transition:transform 0.15s}.styles-module__accountNavigationContainerStyles___3-CuU .user-avatar-picker-wrapper:hover>span>*{transform:scale(1.05, 1.05)}.styles-module__accountNavigationContainerStyles___3-CuU .user-avatar-picker-wrapper:not(.loader-visible):hover>.user-avatar-picker-shadow{bottom:0}@media (max-width: 950px){.styles-module__accountNavigationContainerStyles___3-CuU{grid-template-columns:auto}}\n", ""]);
// Exports
exports.locals = {
	"accountNavigationContainerStyles": "styles-module__accountNavigationContainerStyles___3-CuU"
};
module.exports = exports;
