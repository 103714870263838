import React, { useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import { Button, Card, PlaneLoader, Typography, Tooltip } from 'components'
import { resourcesContainerStyles } from './styles.module.scss'

const LinkButton = ({ moreInfoLink }) => (
  <Button type="primary">
    <a target="_blank" href={moreInfoLink}>
      Learn More
    </a>
  </Button>
)

const Description = ({ children }) => (
  <Typography.Paragraph ellipsis={{ rows: 4 }}>{children}</Typography.Paragraph>
)

const ResourcesContainer = props => {
  useEffect(() => {
    props.resourcesStore.getResources()
  }, [])

  return (
    <div className={resourcesContainerStyles}>
      <PlaneLoader loading={props.resourcesStore.areResourcesLoading}>
        <div className="resources-wrapper">
          {props.resourcesStore.resources.map(resource => (
            <Card
              key={resource.id}
              cover={<img alt="image" src={resource.imageUrl} />}
              actions={[<LinkButton moreInfoLink={resource.moreInfoLink} />]}
            >
              <Card.Meta
                title={<Tooltip placement="topLeft" title={resource.title}>{resource.title}</Tooltip>}
                description={<Description>{resource.description}</Description>}
              />
            </Card>
          ))}
        </div>
      </PlaneLoader>
    </div>
  )
}

export default inject('resourcesStore')( observer (ResourcesContainer));
