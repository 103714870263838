import React from 'react'
import { Tag } from 'antd'
import './styles.scss'

const PPTag = props => (
  <Tag {...props} className={`custom-tag ${props.className || ''}`} />
)

export default PPTag

