// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles-module__comingSoonContainerStyles___3VH2w{display:flex;align-items:center;justify-content:center;width:100%;height:100%}\n", ""]);
// Exports
exports.locals = {
	"comingSoonContainerStyles": "styles-module__comingSoonContainerStyles___3VH2w"
};
module.exports = exports;
