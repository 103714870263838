import React from 'react'
import { Form } from 'antd'
import './styles.scss'

const PPForm = ({ layout = 'vertical', hideRequiredMark = true, colon = false, ...props }) => (
  <Form {...props} layout={layout} hideRequiredMark={hideRequiredMark} colon={colon} className={`custom-form ${props.className || ''}`} />
)

export default PPForm

