import React from 'react'
import { Avatar, Dropdown, Menu, Button, Popover, Modal, Badge } from 'components'
import { Link } from 'react-router-dom'
import { headerContainerStyles } from './styles.module.scss'
import { observer, inject } from 'mobx-react'
import { accountPath } from 'utils/path-helpers/app'
import { getLogoPath } from 'utils/instances'
import classNames from 'classnames'
import { WarningOutlined, QuestionCircleOutlined, MenuOutlined } from '@ant-design/icons'
import {
  navigateToCareer,
  navigateToTimelineAssistant,
  navigateToSubscriptions
} from 'utils/navigation'

const HeaderContainer = props => {
  const onResetProfileClick = () => {
    Modal.confirm({
      title: 'You are about to retake survey.',
      content: 'Retaking the survey will cause you to lose your current progress. Are you sure you want to continue?',
      icon: <WarningOutlined />,
      maskClosable: true,
      closable: true,
      okText: 'Delete My Data',
      cancelText: 'Cancel',
      onOk: props.careerStore.resetProfileProgress,
    })
  }

  const demoMenuItems = [
    {
      key: 'Career Planner Demo',
      title: (
        <Badge dot={!props.userDataStore.user.jsonStorage.isPlannerDemoFinished}>
          Career Planner Demo
        </Badge>
      ),
      action: () => {
        if (!props.careerStore.isActivePathPresented) {
          Modal.info({
            title: 'No pathway selected',
            content: 'In order to see the demo you need to select pathway',
          })

          return
        }
        navigateToCareer()
        props.userDataStore.setSkipPlannerDemo(true)
        props.userDataStore.setShowPlannerDemo(true)
      },
      isSeen: props.userDataStore.user.jsonStorage.isPlannerDemoFinished,
    },
    {
      key: 'Timeline Assistant Demo',
      title: (
        <Badge dot={!props.userDataStore.isSubscriptionFree && !props.userDataStore.user.jsonStorage.isTimelineDemoFinished}>
          Timeline Assistant Demo
        </Badge>
      ),
      action: () => {
        if (props.userDataStore.isSubscriptionFree) {
          Modal.confirm({
            title: 'Premium Account Required',
            content: 'In order to access Timeline Assistant, you need a Premium account. U.S. Military Veterans receive free access to premium forever. Click below to get started.',
            okText: 'Upgrade',
            cancelText: 'Cancel',
            onOk: navigateToSubscriptions,
          })

          return
        }

        navigateToTimelineAssistant()
        props.userDataStore.setSkipTimelineDemo(true)
        props.userDataStore.setShowTimelineDemo(true)
      },
      isSeen: props.userDataStore.user.jsonStorage.isTimelineDemoFinished,
    }
  ]

  const demoMenu = (
    <Menu className="header-demo-menu">
      {demoMenuItems.map(demo => (
        <Menu.Item key={demo.key} onClick={demo.action}>
          {demo.title}
        </Menu.Item>
      ))}
    </Menu>
  )

  const accountMenuItems = [
    (
      <Menu.Item key="account">
        <Link to={accountPath}>
          Account
        </Link>
      </Menu.Item>
    ),
    (
      <Menu.Item key="retake" onClick={onResetProfileClick}>
        Retake survey
      </Menu.Item>
    ),
    (
      <Menu.Item key="logout" onClick={props.userDataStore.logoutUser}>
        Log out
      </Menu.Item>
    ),
  ]

  if (window.env !== 'prod') {
    accountMenuItems.push(
      <Menu.Item key="reset" onClick={props.careerStore.resetProfileData}>
        Reset profile
      </Menu.Item>
    )
  }

  const accountMenu = (
    <Menu>
      {accountMenuItems}
    </Menu>
  )

  const wrapperClass = classNames({
    'header-user-info-wrapper': true,
    'loader-visible': props.userDataStore.isUserDataLoading,
  })

  return (
    <header className={headerContainerStyles}>
      <img className="header-logo" src={getLogoPath()} alt="logo"/>
      <div className="header-toolbar-wrapper">
        <Dropdown disabled={props.userDataStore.isUserDataLoading} overlay={demoMenu} trigger={['click']} placement="bottomRight">
          <Popover
            placement="topRight"
            content="Click to View Demos"
            trigger={['hover', 'click']}
          >
            <Badge dot={!props.userDataStore.areDemosCompleted}>
              <QuestionCircleOutlined />
            </Badge>
          </Popover>
        </Dropdown>
        <Dropdown disabled={props.userDataStore.isUserDataLoading} overlay={accountMenu} trigger={['click']} placement="bottomRight">
          <div className={wrapperClass}>
            <Avatar src={props.userDataStore.user.avatar}>
              {props.userDataStore.initials}
            </Avatar>
            <h3>
              {props.userDataStore.fullName}
            </h3>
          </div>
        </Dropdown>
        <Button
          className="header-burger-menu"
          onClick={props.adaptiveStore.showAsideMenu}
          shape="circle"
          type="primary"
        >
          <MenuOutlined />
        </Button>
      </div>
    </header>
  )
}

export default inject('masterStore')(inject('adaptiveStore')(inject('careerStore')(inject('userDataStore')(observer (HeaderContainer)))))
