import { types, flow, addMiddleware } from "mobx-state-tree"
import { surveyUnitPath } from 'utils/path-helpers/api'
import axios from 'axios'
import { pathwaysSelectionStore } from 'stores'
import { Modal } from 'components'

const SurveyOption = types
  .model({
    id: '',
    value: '',
    options: types.array(types.optional(types.late(() => SurveyOption)), []),
  })

const SurveyQuestion = types
  .model({
    id: '',
    title: '',
    type: '',
    renderType: '',
    options: types.optional(types.array(SurveyOption), []),
    annotation: '',
  })

const SurveyUnit = types
  .model({
    id: '',
    title: '',
    questions: types.optional(types.array(SurveyQuestion), []),
  })

const SurveyStore = types
  .model("SurveyStore", {
    unit: SurveyUnit,
    isSQSkeletonVisible: false,
    isSurveyAnswerSaving: false,
  })
  .views(self => ({
    get unitName() {
      return self.unit && self.unit.title
    },

    get questionsArray() {
      return self.unit && self.unit.questions
    },
  }))
  .actions(self => ({
    getSurveyUnit: flow(function*(showSQSkeleton = true) {
      try {
        self.isSQSkeletonVisible = showSQSkeleton
        const response = yield axios.get(surveyUnitPath())

        if (response.data.id) {
          if (!self.unit.id) {
            Modal.info({
              title: 'Welcome to Pilot Pipeline!',
              content: 'Get started by answering our survey questions.',
              okText: 'Let’s Go!',
              icon: null,
              maskClosable: true,
            })
          }
          pathwaysSelectionStore.getPaths()
        }

        if (!response.data.id) {
          if (self.unit.id) {
            Modal.success({
              title: 'Awesome Work!',
              content: 'Based on your background and preferences we have built you customized pathways. Ready to see them?',
              okText: 'Yes',
              maskClosable: true,
            })
          }
          pathwaysSelectionStore.getPaths()
        }

        self.setSurveyUnit(response.data || {})
      } catch (error) {
        console.log(error)
      }
      self.isSQSkeletonVisible = false
    }),
    saveSurveyAnswer: flow(function*(payload) {
      try {
        self.isSurveyAnswerSaving = true
        yield axios.post(surveyUnitPath(), payload)
        yield self.getSurveyUnit(false)
      } catch (error) {
        console.log(error)
      }
      self.isSurveyAnswerSaving = false
    }),
    setSurveyUnit: unit => {
      self.unit = unit
    }
  }))

export const surveyStore = SurveyStore.create({ unit: {} })
//addMiddleware(surveyStore, (call, next, abort) => {
//  console.log(call)
//  next(call)
//})