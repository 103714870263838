import React from 'react'
import { Input } from 'antd'
import './styles.scss'

const PPInput = props => (
  <Input {...props} className={`custom-input ${props.className || ''}`} />
)

export default PPInput

