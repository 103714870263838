export const SUBSCRIPTIONS_TYPES = type => {
  const subscr = {
    free: 'Standard',
    premium: 'Premium',
    premiumPlus: 'Premium Plus',
  }

  return subscr[type] || 'Standard'
}

export const COUPON_DURATION = {
  ONCE: 'once',
  REPEATING: 'repeating',
  FOREVER: 'forever',
}
