import React from 'react'
import { Radio } from 'antd'
import './styles.scss'

const PPRadio = props => (
  <Radio {...props} className={`custom-radio ${props.className || ''}`} />
)

export default PPRadio

