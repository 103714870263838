import React from 'react'
import { Button, Input, Form, Link } from 'components'
import { authContainerStyles } from './styles.module.scss'
import { observer, inject } from 'mobx-react'
import { CenteredLayout } from 'components'
import { authPath } from 'utils/path-helpers/app'
import { getLogoPath } from 'utils/instances'

const rules = [
  {
    required: true,
    message: 'This field is required',
  }
]

const initialValues = {
  email: ''
}

const ForgotPasswordContainer = props => {
  const onFormSubmit = values => {
    const payload = {
      email: values.email,
    }
    props.userDataStore.requestPasswordRestore(payload)
  }

  return (
    <CenteredLayout>
      <div className={authContainerStyles}>
        <img className='auth-container-logo' src={getLogoPath()} alt="logo"/>
        <Form id="forgot-password-form" onFinish={onFormSubmit} initialValues={initialValues}>
          <Form.Item className="auth-container-input" label="Email" name="email" rules={rules} validateTrigger="onBlur">
            <Input placeholder="Email"/>
          </Form.Item>
        </Form>
        <Button type="primary" color="secondary" className="register-container-button" >
          <Link to={authPath()}>
            Back to login
          </Link>
        </Button>
        <Button loading={props.userDataStore.isLoginInProgress} form="forgot-password-form" htmlType="submit" type="primary" className="login-container-button">
          Reset
        </Button>
      </div>
    </CenteredLayout>
  )
}

export default inject('userDataStore')(observer (ForgotPasswordContainer))
