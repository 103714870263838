import React, { useState } from 'react'
import { subscriptionsNavigationContainerStyles } from './styles.module.scss'
import { TileNavigator, Breadcrumb, Button, Skeleton } from 'components'
import * as APP_PATHS from 'utils/path-helpers/app'
import { observer, inject } from 'mobx-react'
import classNames from 'classnames'
import { isFlexAirInstance } from 'constants/instances'

const tiles = [
  {
    label: 'Upgrade Account',
    to: APP_PATHS.upgradeSubscriptionPath(),
  },
  {
    label: 'Discounts',
    to: APP_PATHS.discountSubscriptionPath(),
  },
]

const SubscriptionsNavigationContainer = props => {
  const [modalVisible, setModalVisible] = useState(false)
  const [avatar, setAvatar] = useState(null)

  const onCropEnd = image => {
    setAvatar(image)
  }

  const onOk = () => {
    props.userDataStore.uploadCurrentUserAvatar(avatar)
    setModalVisible(false)
  }

  const onCancel = () => {
    setModalVisible(false)
  }

  const wrapperClass = classNames({
    'user-avatar-picker-wrapper': true,
    'loader-visible': props.userDataStore.isUserDataLoading,
  })

  const returnSubscriptionInfo = () => (
    <div>
      <span>
        {'Subscription: '}
      </span>
      <span>
        {props.userDataStore.user.activeSubscriptionName}
      </span>
      <br />

      <span>
        {'Renews: '}
      </span>
      <span>
        {props.userDataStore.subscriptionRenewsDate}
      </span>
      <br />

      <span>
        {'Price: '}
      </span>
      <span>
        {props.userDataStore.subscriptionPrice}
      </span>
      <br />

      {props.userDataStore.isStripeSubscriptionActive ? <Button onClick={props.userDataStore.cancelSubscription}>Cancel Subscription</Button> : null}
    </div>
  )

  const getTiles = () => {
    if (isFlexAirInstance) {
      return null
    }

    return <TileNavigator tiles={tiles} />
  }

  return (
    <div className={subscriptionsNavigationContainerStyles}>
      <Breadcrumb className="account-tile-breadcrumb" label="Back" to={APP_PATHS.accountPath()} />
      <div className="account-subscription-panel-wrapper">
        {props.userDataStore.isSubscriptionInfoLoading ? <Skeleton /> : returnSubscriptionInfo()}
      </div>

      {getTiles()}
    </div>
  )
}

export default inject('userDataStore')(observer (SubscriptionsNavigationContainer))
