// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles-module__contentContainerStyles___1S9qB{overflow:auto;height:calc(100vh - 110px);display:flex;flex-direction:column}@media (max-width: 950px){.styles-module__contentContainerStyles___1S9qB{height:calc(100vh - 50px)}}\n", ""]);
// Exports
exports.locals = {
	"contentContainerStyles": "styles-module__contentContainerStyles___1S9qB"
};
module.exports = exports;
