import React from 'react'
import { observer, inject } from 'mobx-react'
import { SurveyContainer, PathwaySelectionContainer } from 'containers'

const PathwaysContainer = props => {
  if (props.surveyStore.unit.id) {
    return <SurveyContainer />
  } else {
    return <PathwaySelectionContainer />
  }
}

export default inject('surveyStore')(observer (PathwaysContainer))
